import React from 'react'
import {TextField,Button,CircularProgress,IconButton,OutlinedInput,InputAdornment,InputLabel,FormControl} from '@material-ui/core'
import {Visibility,VisibilityOff,} from '@material-ui/icons'
import {useDispatch,useSelector} from 'react-redux'
import {loginTalenta} from 'redux/actions/auth'
import wla_login from 'assets/image/wla_login.png'
import { MuiThemeProvider, createTheme} from '@material-ui/core/styles'
import {useHistory} from 'react-router-dom'
import OneTalentBlack from 'assets/image/OneTalentBlack.png'
const themeButton = createTheme({ 
    palette: { 
        primary: {
            main:'#afe597',
            contrastText: '#FFFFFF',

        },
        secondary: {
            main:'#65b7ff',
            contrastText: '#FFFFFF',

        },
    } 
})

 function Index(props) {
    let history=useHistory()

    const dispatch=useDispatch()
    const general = useSelector(state => state.general)
    const [success, setsuccess] = React.useState(false)
    const [state,setState]=React.useState({
        show_pass:false,
        email:'',
        password:'',
        error_msg:'',
        loading:false
    })
    const passwordToggle=()=>{
        setState({...state,show_pass:!state.show_pass})
    }
    const onChange=(e)=>{
        setState({...state,[e.target.name]:e.target.value})
    }
    const onSubmit=async (e)=>{
        e.preventDefault()
        // history.push("/talenta/kegiatan");
        let {email,password}=state
        let data={
            userName:email,
            password,
            os:'windows',
            deviceID: 1,
            sourceID: 1,
            versionCode: 1,
            versionName: "string"
        }
        dispatch(loginTalenta(data))
        // if(res){
        //     history.push("/talenta/kegiatan");
        // }
    }
    const {show_pass}=state
    
    return (
        <div data-testid="login-wrapper">
            {success&&<h1 data-testid="head-success">success login</h1>}
            <div  className='login-wrapper'>
                <MuiThemeProvider theme={themeButton}>
                <div className='login-form'>
                    <img src={OneTalentBlack} style={{width:'60%'}}/>
                    
                    <p>One Talent membantu Anda dalam memetakan kekuatan Talenta yang berpotensi sebagai Suksesor Pemimpin masa depan organisasi Anda. </p>
                    <h3>Login</h3>
                    <form data-testid="form-submit" onSubmit={onSubmit}>

                    <TextField
                        label="Email / Username"
                        variant="outlined"
                        size="small"
                        className='form-login'
                        name='email'
                        value={state.email}
                        onChange={onChange}
                        required
                        color='secondary'
                        style={{marginBottom:20}}
                        inputProps={{ "data-testid": "email-field" }}
                    />
                    
                    <FormControl className='form-login' color='secondary'  variant="outlined" size="small">
                        <InputLabel  htmlFor="outlined-adornment-password">Password <span >*</span></InputLabel>
                        <OutlinedInput
                            className='form-login'
                            id="outlined-adornment-password"
                            type={show_pass ? 'text' : 'password'}
                            name='password'
                            onChange={onChange}
                            required
                            value={state.password}
                            color='secondary'
                            inputProps={{ "data-testid": "password-field" }}
                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={passwordToggle}
                                edge="end"
                                data-testid="eye-toggle"
                                >
                                {show_pass ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                            labelWidth={85}
                        />
                    </FormControl>
                    
                    {general.error_msg!==null&&general.error_msg.map((data,i)=>(
                        <p name="error-msg" className='error-msg' data-testid="error-msg" className='bold' key={i} style={{color:'red',margin:0}}>{data.description}</p>
                    ))}
                    <br/>
                        <Button disabled={general.isLoadingTable} size='large' type='submit' color="secondary" className='btn-remove-capital btn-rounded btn-login' variant='contained'>
                            {general.isLoadingTable?<CircularProgress data-test='loading-circular' style={{color:'white'}}  size={25} />:'Masuk'}
                        </Button>
                    </form>
                    <br/>
                </div>
                <div className='img-bg'>
                    <img src={wla_login} alt="login"/>
                </div>
                </MuiThemeProvider>
                <br/>
                <br/>

            </div>
        </div>
    )
}

export default Index