import React,{useState,useEffect} from 'react'
import {Button,TextField,Collapse,FormControl,MenuItem,InputLabel,Select} from '@material-ui/core'
import AutoCompleteSelect from 'components/Select'
import Cookie from 'universal-cookie'
const cookie=new Cookie
const profile=cookie.get('profile_cookie')
const HirarkiForm=(props)=>{
    let {
        classes,
        periode,
        type,
        onChangeSub,
        hirarkiIndex1,
        hirarkiIndex2,
        hirarkiIndex3,
        detail,
        is_edited
    }=props
    const [keterangan, setketerangan] = useState(detail.keterangan)
    useEffect(() => {
        // console.log(`detail.keterangan`, detail.keterangan)
        setketerangan(detail.keterangan)
    }, [detail])
    let periodes=[
        {id:1,text:'Harian',value:228},
        {id:2,text:'Mingguan',value:52},
        {id:3,text:'Bulanan',value:12},
        {id:4,text:'Triwulanan',value:4},
        {id:5,text:'Semesteran',value:2},
        {id:6,text:'Tahunan',value:1},
    ]
    let findPeriodeText=(periode)=>{
        if(periode!==null){
            let filter=periodes.filter(f=>{
                return f.id===periode.id
            })
            if(filter.length>0){
                return filter[0]
            }else{
                return {id:0,text:''}
            }
        }else{
            return {id:0,text:''}
        }
        
    }
    let total_beban_kerja=findPeriodeText(detail.periode).value*detail.kuantitas*detail.durasi*detail.pegawai
    
    return(
            <div style={{width:'93%',marginBottom:20}}>
                <div className='div-flex' style={{marginBottom:10}}>
                
                {/* <FormControl style={{width:450}} size='small' variant='outlined' className={classes.textField}>
                    <InputLabel >Period</InputLabel>
                    <Select
                        value={detail.periode}
                        onChange={(e)=>onChangeSub('periode',e.target.value,hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)}
                        labelWidth={45}
                        disabled={!is_edited}
                        // inputProps={autofocus: {true}}
                    >
                        {periode.map((data,i)=>(
                            <MenuItem key={i} value={data.id}>{data.text}</MenuItem>

                        ))}
                    </Select>
                </FormControl> */}
                    <AutoCompleteSelect
                        style={{width:450}}

                        // onChange={(event,value)=>onChange(value)}
                        onChange={(event,value)=>onChangeSub('periode',value,hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)}
                        options={periode}
                        value={detail.periode}
                        getOptionLabel={(option) => option.text}
                        getOptionSelected={(opt,value)=>opt.text}
                        label={<>Periode</>}
                        disableClearable
                        // multiple
                        disabled={!is_edited}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <TextField
                        style={{width:340}}
                        disabled={!is_edited}
                        label="Kuantitas"
                        variant='outlined'
                        type='number'
                        placeholder="0"
                        value={detail.kuantitas}
                        size='small'
                        className={classes.textField}
                        // InputLabelProps={{
                        //     classes: {
                        //       root: classes.cssLabel,
                        //       focused: classes.cssFocused,
                        //     },
                        //   }}
                        //   InputProps={{
                        //     classes: {
                        //       root: classes.cssOutlinedInput,
                        //       focused: classes.cssFocused,
                        //       notchedOutline: classes.notchedOutline,
                        //     },
                        //     inputMode: "numeric"
                        //   }}
                        onChange={(e)=>onChangeSub('kuantitas',e.target.value,hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <TextField
                        // style={{width:'24%'}}
                        // style={{width:profile.roleId!==5&&'%'}}
                        disabled={!is_edited}
                        label="Durasi pengerjaan (menit)"
                        variant='outlined'
                        type='number'
                        placeholder="0"
                        value={detail.durasi}
                        size='small'
                        className={classes.textField}
                        onChange={(e)=>onChangeSub('durasi',e.target.value,hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <TextField
                        style={{width:profile.roleId!==5&&'80%'}}
                        disabled={!is_edited}
                        label="Jml pegawai terlibat"
                        variant='outlined'
                        type='number'
                        placeholder="0"
                        value={detail.pegawai}
                        size='small'
                        className={classes.textField}
                        onChange={(e)=>onChangeSub('pegawai',e.target.value,hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)}
                    />
                    &nbsp;&nbsp;&nbsp;
                    {/* <FormControl style={{width:500}} size='small' variant='outlined' className={classes.textField}>
                        <InputLabel id="demo-simple-select-label">Jenis Tugas</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={detail.jenis_tugas}
                            // defaultValue={detail.jenis_tugas}

                            onChange={(e)=>onChangeSub('jenis_tugas',e.target.value,hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)}
                            labelWidth={85}
                            disabled={!is_edited}
                        >
                            {type.map((data,i)=>(
                                <MenuItem key={i} value={data.id}>{data.text}</MenuItem>

                            ))}
                        </Select>
                    </FormControl> */}
                    {/* <AutoCompleteSelect
                        style={{width:500}}
                        onChange={(event,value)=>onChangeSub('jenis_tugas',value,hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)}
                        options={type}
                        value={detail.jenis_tugas}
                        getOptionLabel={(option) => option.text}
                        label={<>Jenis Tugas</>}
                        disableClearable

                        // multiple
                        disabled={!is_edited}
                    /> */}
                    <AutoCompleteSelect
                        style={{width:500}}

                        // onChange={(event,value)=>onChange(value)}
                        onChange={(event,value)=>onChangeSub('jenis_tugas',value,hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)}
                        options={type}
                        value={detail.jenis_tugas}
                        getOptionLabel={(option) => option.text}
                        getOptionSelected={(opt,value)=>opt.text}
                        label={<>Jenis Tugas</>}
                        disableClearable
                        // multiple
                        disabled={!is_edited}
                    />
                    {profile.roleId!==5&&
                    <>
                    &nbsp;&nbsp;&nbsp;
                    <TextField
                        error={total_beban_kerja>50000?true:false}
                        style={{width:400}}
                        disabled={true}
                        label="Beban Kerja"
                        variant='outlined'
                        type='text'
                        value={Number.isNaN(total_beban_kerja)?'-':total_beban_kerja.toLocaleString()}
                        size='small'
                        InputProps={{
                            classes: {
                               root: total_beban_kerja>50000?classes.root:classes.root2,
                               disabled: classes.disabled,
                               notchedOutline: classes.notchedOutline,
                            }
                         }}
                        onChange={(e)=>onChangeSub('pegawai',e.target.value,hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)}
                    /></>}
                    
                </div>
                <div >
                    <TextField
                        // style={{width:'90%'}}
                        label="Keterangan"
                        variant='outlined'
                        type='number'
                        disabled={!is_edited}
                        value={keterangan}
                        size='small'
                        multiline
                        className={classes.textField}
                        onChange={(e)=>setketerangan(e.target.value)}
                        onBlur={(e)=>onChangeSub('keterangan',keterangan,hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)}
                    />
                </div>
        </div>
    )
}

export default HirarkiForm