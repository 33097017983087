import React, { useState,useEffect } from 'react'
import './style.css'
import {TextField,Button,CircularProgress,IconButton,OutlinedInput,InputAdornment,InputLabel,FormControl} from '@material-ui/core'
import {Visibility,VisibilityOff,} from '@material-ui/icons'
import {useDispatch,useSelector} from 'react-redux'
import {login} from 'redux/actions/auth'
import {resetHirarki} from 'redux/actions/hirarki'
import {resetResponden} from 'redux/actions/responden'
import {resetProyek} from 'redux/actions/proyek'
import wla_login from 'assets/image/wla_login.png'
import { MuiThemeProvider, createTheme} from '@material-ui/core/styles'
import {withRouter} from 'react-router-dom'
import Loader from 'components/Loading'
const themeButton = createTheme({ 
    palette: { 
        primary: {
            main:'#afe597',
            contrastText: '#FFFFFF',

        },
        secondary: {
            main:'#65b7ff',
            contrastText: '#FFFFFF',

        },
    } 
})

 function Index(props) {

    const dispatch=useDispatch()
    const general = useSelector(state => state.general)
    useEffect(() => {
        dispatch(resetHirarki())
        dispatch(resetResponden())
        dispatch(resetProyek())
    }, [])
    const [state,setState]=useState({
        show_pass:false,
        email:'',
        password:''
    })
    const passwordToggle=()=>{
        setState({...state,show_pass:!state.show_pass})
    }
    const onChange=(e)=>{
        setState({...state,[e.target.name]:e.target.value})
    }
    const onSubmit=(e)=>{
        e.preventDefault()
        let {email,password}=state
        let data={
            userName:email,
            password,
            os:'windows',
            deviceID: 1,
            sourceID: 1,
            versionCode: 1,
            versionName: "string"
        }
        dispatch(login(data))
    }
    const {show_pass}=state
    return (
        <div>
            <Loader/>
            <div className='login-wrapper'>
                <MuiThemeProvider theme={themeButton}>

                <div className='login-form'>
                    <img alt="login" src={wla_login} className='img-bg-mobile'/>
                    <h1>Workload Analysis</h1>
                    <p>Menghitung untuk memperoleh informasi mengenai tingkat efektivitas dan efisiensi kerja organisasi berdasarkan volume kerja.</p>
                    <h3>Login</h3>
                    <form onSubmit={onSubmit}>
                    <TextField
                        label="Email / Username"
                        variant="outlined"
                        size="small"
                        className='form-login'
                        name='email'
                        onChange={onChange}
                        required
                        color='secondary'
                        style={{marginBottom:20}}
                    />
                    
                    <FormControl className='form-login' color='secondary'  variant="outlined" size="small">
                        <InputLabel  htmlFor="outlined-adornment-password">Password <span >*</span></InputLabel>
                        <OutlinedInput
                            className='form-login'
                            id="outlined-adornment-password"
                            type={show_pass ? 'text' : 'password'}
                            name='password'
                            onChange={onChange}
                            required
                            color='secondary'

                            endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                aria-label="toggle password visibility"
                                onClick={passwordToggle}
                                edge="end"
                                >
                                {show_pass ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                            }
                            labelWidth={85}
                        />
                    </FormControl>
                    {general.error_msg!==null&&general.error_msg.map((data,i)=>(
                        <p className='bold' key={i} style={{color:'red',margin:0}}>{data.description}</p>
                    ))}
                    <br/>
                        <Button disabled={general.isLoadingTable} size='large' type='submit' color="secondary" className='btn-remove-capital btn-rounded btn-login' variant='contained'>
                            {general.isLoadingTable?<CircularProgress style={{color:'white'}}  size={25} />:'Masuk'}
                        </Button>
                        {/* <p>Masuk sebagai <a href="/loginadmin" style={{textDecoration:'none',color:'#00a1ed',fontWeight:'bold'}}>Admin</a></p> */}
                    </form>
                    <br/>
                </div>
                <div className='img-bg'>
                    <img src={wla_login} alt="login"/>
                </div>
                </MuiThemeProvider>
                <br/>
                <br/>

            </div>
        </div>
    )
}

export default withRouter(Index)