import React, { useState } from 'react'
import DataTable from 'components/DataTable'
import { TableRow,TableCell } from '@material-ui/core'
import SearchTable from 'components/SearchTable'
import _ from 'lodash'
const RenderHeadRiwayatKredit=(props)=>(
    <div className='div-flex div-space-between' style={{alignItems:'flex-end'}}>
        <SearchTable
            {...props}
            searchToggle={(value)=>props.searchToggle(value)}
        />
    </div>
)
export default function Riwayat_kredit(props) {
    const [search, setsearch] = useState('')
    const searchToggle=_.debounce(async (e)=>{
        setsearch(e)

    },1000)
    return (
        <div>
            <div className='head-section'>
                <h4><b>Riwayat Kredit</b></h4>
            </div>
            <br/>
            <DataTable
                head={['No.','Aktifitas','Tanggal','Keterangan','Jumlah','Sisa Kredit']}
                body={()=>{
                    // return client_detail.admins.map((data,i)=>(
                    //     <TableRow key={i} data-testid="proyek-item">
                    //         <TableCell style={{width:10}}>{i+1}</TableCell>
                    //         <TableCell>{data.email}</TableCell>
                    //         <TableCell  style={{display:'flex'}}>
                    //             <p onClick={()=>resetPassword(data)} style={{margin:0,cursor:'pointer',color:'#00a1ed',fontWeight:'bold',fontSize:12,}}>Reset Password</p>
                    //             <img src={Close} onClick={()=>deleteAdmin(data)} className='icon-action'/>
                    //         </TableCell>
                    //     </TableRow>
                    // ))
                }}
                cardHead={<RenderHeadRiwayatKredit {...props}  searchToggle={searchToggle} />}
                loading={false}
                pagination={null}
                togglePagination={null}
            />
            <br/>

        </div>
    )
}
