import React from 'react'
import SearchTable from 'components/SearchTable'
import Close from 'assets/icon/close.svg'
import Eye from 'assets/icon/eye.png'
import _ from 'lodash'
import DataTable from 'components/DataTable'
import {Button,TableRow} from '@material-ui/core'
import { useSelector,useDispatch } from 'react-redux'
import {getIndustry} from 'redux/actions/client'
import {TableCell} from '@material-ui/core'
import {modalToggle} from 'redux/actions/general'
import * as actionSa from 'redux/actions/sa'
import * as actionGeneral from 'redux/actions/general'

const RenderHead=(props)=>(
    <div className='div-flex div-space-between' style={{alignItems:'flex-end'}}>
        <SearchTable
            {...props}
            searchToggle={(value)=>props.searchToggle(value)}
        />
        <Button onClick={props.filterData} color="primary" className='btn-remove-capital' variant="text">Filter</Button>
    </div>
)
export default function List(props) {
    const [search, setsearch] = React.useState('')
    let {profile}=props
    const sa = useSelector(state => state.sa)
    const client = useSelector(state => state.client)
    const general = useSelector(state => state.general)
    let {list_industry}=client
    let {client_list,client_pagination,client_filter,client_detail}=sa
    const dispatch = useDispatch()
    React.useEffect(()=>{
        if(list_industry.length<1){
            dispatch(getIndustry())
        }
        getClient(`/0/1/10/*`)
    },[])
    const getClient=async (slug)=>{
        dispatch(actionSa.getClientList(slug))
    }
    const searchToggle=_.debounce(async (e)=>{
        setsearch(e)
        getClient(`/0/1/10/${e!==''?e:'*'}`)
    },1000)
    const togglePagination=(page,pageLength)=>{
        let new_industry=[]

        client_filter.industry.map((data)=>{
            new_industry.push(data.id)
        })
        getClient(`/${new_industry.length>0?new_industry.join(','):0}/${page}/${pageLength}/${search!==''?search:'*'}`)
    }
    const afterPostFilter=(data)=>{
        dispatch(actionSa.setSa({client_filter:{industry:data}}))
        // dispatch(setFilterClient({industry:data}))
    }
    const filterData=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Filter`,
            modal_component: "filter_client",
            modal_size:300,
            modal_data:{industry:client_filter.industry,afterPostFilter:(data)=>afterPostFilter(data)},
            modal_action:'filter_client'
        }))
    }
    const afterPost=()=>{
        // dispatch(actionGeneral.modalToggleReset())
        getClient(`/0/1/10/*`)
    }
    const toAddClient=()=>{
        dispatch(actionSa.setSa({client_detail:{
            address:'',
            company:'',
            credit:0,
            id:0,
            industry:null,
            logoURL:null,
            phone:''
        }}))
        dispatch(actionSa.setSa({client_admin:[]}))
        dispatch(actionGeneral.modalToggleReset())
        props.tabToggle('add')

    }
    const addClient=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Tambah Klien`,
            modal_component: "sa_add_client",
            modal_size:400,
            modal_data:{
                afterPost:()=>afterPost(),
                addClient:()=>toAddClient()
            },
            modal_action:'sa_add_client'
        }))
    }
    const detailClient=async (data)=>{
        dispatch(actionSa.setSa({client_detail:{...client_detail,id:data.clientId}}))
        props.tabToggle('detail')
        // let res=await dispatch(actionSa.getDetailClient(data.clientId))
        // if(res){
        //     console.log(`res.data`, res.data)
            
        // }
    }
    const action_delete=async (data)=>{
        let res=await dispatch(actionSa.deleteClient(`/${data.clientId}/${props.profile.id}`))
        if(res){
            getClient(`/0/1/10/*`)
        }
        
    }
    const deleteClient=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Client',
                userId:data.id,
                clientId:data.clientId,
                action:()=>action_delete(data),
                msg:`<p>Apakah anda yakin menghapus ${data.company} </p>`
            },
            modal_action:'delete_client'
        }))
    }
    return (
        <div>
            <div data-testid="list-wrapper">
                <div className='head-section'>
                    <h4 data-testid="heading"><b>Manajemen Klien</b></h4>
                    <Button onClick={addClient} className='btn-remove-capital btn-rounded' variant='contained' color="primary">Tambah Client</Button>
                </div>
                <DataTable
                    head={['No.','Nama Perusahaan','Kategori','Jumlah Kegiatan','Jumlah Kredit','Aksi']}
                    body={()=>{
                        return client_list.map((data,i)=>(
                            <TableRow key={i} data-testid="proyek-item">
                                <TableCell>{i+1}</TableCell>
                                <TableCell>{data.company}</TableCell>
                                <TableCell>{data.industry.text}</TableCell>
                                <TableCell>{data.creditTerpakai}</TableCell>
                                <TableCell>{data.creditTotal}</TableCell>
                                <TableCell align="right">
                                    <img src={Eye} onClick={()=>detailClient(data)} className='icon-action'/>
                                    <img src={Close} onClick={()=>deleteClient(data)} className='icon-action'/>
                                </TableCell>
                            </TableRow>
                        ))
                    }}
                    cardHead={<RenderHead filterData={filterData} {...props}  searchToggle={searchToggle} />}
                    loading={general.isLoadingTable}
                    pagination={client_pagination}
                    togglePagination={togglePagination}
                />
            </div>
        </div>
    )
}
