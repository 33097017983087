import {apiCall} from 'service/apiCall'
import {setLoading,setError,modalToggle,setLoadingTable} from './general'
import Cookie from 'universal-cookie'
import {get} from 'lodash'
import { USER,PASS } from 'service/base_url'
import {resetHirarki} from './hirarki'
import {resetResponden} from './responden'
import {createMemoryHistory} from 'history'
const cookie=new Cookie()
const token=cookie.get('login_cookie')
export const login=(data)=>async dispatch=>{
    dispatch(setLoadingTable(true))
        let dataReq={
            url:'/auth/login',
            method:'POST',
            data:{data:data,auth:{username:USER,password:PASS}}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoadingTable(false))
            cookie.set('login_cookie',res.data.accessToken.token)
            cookie.set('refresh_cookie',res.data.refreshToken)  
            let profile={
                userName:res.data.userName,
                email:res.data.email,
                profile_pic:res.data.profilePic,
                id:res.data.id,
                // roleId:3,
                roleId:res.data.roleId,
                // roleId:res.data.email==='rafdi@gmlperformance.co.id'?3:res.data.email==='rifky@gmlperformance.co.id'?1:res.data.roleId,
                name:res.data.name,
                clientId:res.data.clientId
            }
            cookie.set('profile_cookie',profile)  

            dispatch(setError(null))
            if(res.data.roleId===4||res.data.roleId===3){

                window.location.assign('/wla/analisis-beban-kerja')
            }else if(res.data.roleId===5){
                await dispatch(resetHirarki())
                await dispatch(resetResponden())
                window.location.assign('/wla/responden')
            }else{
                dispatch(setError([{code:'login_failure',description:'Invalid username or password.'}]))
            }
            return res
            // if(res.data.roleId!==2){
            //     window.location.assign('/dashboard')

            // }else if(res.data.roleId===2){
            //     window.location.assign('/demografi')

            // }
        }else{
            dispatch(setLoadingTable(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}
export const loginTalenta=(data)=>async dispatch=>{
    await dispatch(setLoadingTable(true))
    // dispatch({type:'ADD_TODO'})
    let dataReq={
        url:'/auth/login',
        method:'POST',
        data:{data:data,auth:{username:USER,password:PASS}}
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')===200){
        dispatch(setLoadingTable(false))
        if(res.data.roleId!==3){
            let getLogo=await dispatch(talentaGetLogo(res.data.id))
            // if(get(getLogo,'status')===200){
                cookie.set('login_cookie',res.data.accessToken.token)
                cookie.set('refresh_cookie',res.data.refreshToken)  
                let profile={
                    userName:res.data.userName,
                    email:res.data.email,
                    profile_pic:res.data.profilePic,
                    id:res.data.id,
                    roleId:res.data.roleId,
                    name:res.data.name,
                    clientId:res.data.clientId,
                    logo_url:get(getLogo,'status')===200?getLogo.data.settingString1:null,
                    company:get(getLogo,'status')===200?getLogo.data.company:''
                }
                cookie.set('profile_cookie',profile)  
                dispatch(setError(null))
                window.location.assign('/talenta/mapping')
                    
                return res
            // }
        }else{
            cookie.set('login_cookie',res.data.accessToken.token)
                cookie.set('refresh_cookie',res.data.refreshToken)  
                let profile={
                    userName:res.data.userName,
                    email:res.data.email,
                    profile_pic:res.data.profilePic,
                    id:res.data.id,
                    roleId:res.data.roleId,
                    name:res.data.name,
                    clientId:res.data.clientId,
                    // logo_url:getLogo.data.settingString1
                }
                cookie.set('profile_cookie',profile)  
                dispatch(setError(null))
                window.location.assign('/sa/client')
        }
        
        
    }else{
        dispatch(setLoadingTable(false))
        return res

    }
}
export const talentaGetLogo=(userId)=>async dispatch=>{
    await dispatch(setLoadingTable(true))
    // dispatch({type:'ADD_TODO'})
    let dataReq={
        url:`/auth/settings/talent/${userId}`,
        method:'GET',
        data:{auth:{username:USER,password:PASS}}
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')===200){
        dispatch(setLoadingTable(false))
       
            return res
    }else{
        dispatch(setLoadingTable(false))
        return res

    }
}
export const loginUuid=(uuid)=>async dispatch=>{
        dispatch(setLoading(true))
        let dataReq={
            url:`/auth/login/${uuid}`,
            method:'GET',
            data:{auth:{username:USER,password:PASS}}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))

              cookie.set('login_cookie',res.data.accessToken.token,{path:'/'})
              cookie.set('refresh_cookie',res.data.refreshToken,{path:'/'})  
            let profile={
                userName:res.data.userName,
                email:res.data.email,
                profile_pic:res.data.profilePic,
                id:res.data.id,
                roleId:res.data.roleId
            }
              cookie.set('profile_cookie',profile,{path:'/'})  

            dispatch(setError(null))
            // window.location.assign('/wla/client')
            // window.location.assign('/welcome')
            // if(res.data.roleId===1){
            //     window.location.assign('/dashboard')

            // }else if(res.data.roleId===2){
            //     window.location.assign('/welcome')

            // }
        }else{
            dispatch(setLoading(false))
            // dispatch(setError('The Email or password is incorrect. Please try again '))
        }
}


export const refreshToken=(token,refreshtoken,act)=>{
    return async dispatch=>{
        dispatch(setLoading(true))
        let dataReq={
            url:'/auth/refreshtoken',
            method:'POST',
            data:{
                auth:{username:USER,password:PASS},
                data:{accessToken:token,refreshToken:refreshtoken}
            }
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            cookie.set('login_cookie',res.data.accessToken.token)
            cookie.set('refresh_cookie',res.data.refreshToken)  
            window.location.assign(`/?act=${act}`)
        }else{
            dispatch(setLoading(false))
            dispatch(logout(act))
            // console.log('d',res.data)
        }
    }
}
export const logout=(act)=>async dispatch=>{
    // dispatch(setLoading(true))
    // let dataReq={
    //     url:'/Profile/logout',
    //     method:'GET',
    //     data:{
    //         headers:{ 'Authorization': `Bearer ${token}`}
    //     }
    // }
    // let res=await dispatch(apiCall(dataReq))
    // dispatch(setLoading(false))
    
    cookie.remove('login_cookie')
    cookie.remove('refresh_cookie')
    cookie.remove('profile_cookie')
    if(act){
        window.location.assign(`/?act=${act}`)

    }else{
        window.location.assign(`/?act=wla`)

    }
}
export const remove_cookie=()=>async dispatch=>{
    
    cookie.remove('login_cookie')
    cookie.remove('refresh_cookie')
    cookie.remove('profile_cookie')
}
export const changePassword=(data)=>{
    return async(dispatch)=>{
        dispatch(setLoading(true))
        let dataReq={
            url:'/Profile/changepassword',
            method:'PUT',
            data:{
                headers:{ 'Authorization': `Bearer ${token}`},
                data:data
            }
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            if(res.data.code==='old_password'){
                dispatch(setError(res.data.description))

            }else if(res.data.code==='new_password'){
                dispatch(setError(res.data.description))

            }else if(res.data.code==='ok'){
                dispatch(modalToggle({ 
                    modal_open: true,
                    modal_title: "Change password",
                    modal_component: "change_password",
                    modal_size:400,
                    modal_type:'alert',
                    modal_data:{
                        msg:`<p>${res.data.description}</p> `,
                        ...res.data
                    },
                    modal_action:'success',
                    // success_msg:success_msg
                }))
                // dispatch(alertToggle({ isOpen: true,button_title:'Close',title: "Change password" ,componentName: "alert_client",size:4,message:res.data.description }))
                // dispatch(setError(res.data.description))

            }
        }else{
            console.log('123')
            dispatch(setLoading(false))

        }
       
    }
}
