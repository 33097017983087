import React,{useState} from 'react'
import {Button,TextField} from '@material-ui/core'
import AutoCompleteSelect from 'components/Select'
import {useDispatch,useSelector} from 'react-redux'
import * as actionGeneral from 'redux/actions/general'
import * as actionProyek from 'redux/actions/proyek'
import ChipInput from 'material-ui-chip-input'
import InputMask from 'react-input-mask'

export default function Tambah_proyek(props) {
    const dispatch = useDispatch()
    const [state, setstate] = useState({
        projectId:0,
        name:'',
        clientId:null,
        defaultFTE:null,
        hierarchy:null,
        username:'',
        password:'',
        taskTypes:["Utama", "Berkala/Proyek", "Tambahan"],
        userId:props.profile.id,
    })
    const client = useSelector(state => state.client)
    const proyek = useSelector(state => state.proyek)
    const general = useSelector(state => state.general)
    const {name,clientId,defaultFTE,hierarchy,username,password,projectId,userId,taskTypes}=state
    const addKlient=()=>{
        dispatch(actionGeneral.modalToggle({
            modal_open: true,
            modal_title: `Tambah Klien`,
            modal_component: "tambah_client",
            modal_size:500,
            modal_data:null,
            modal_action:'tambah_client'
        }))
    }
    const onClickSimpan=async()=>{
        let data={
            projectId,
            name,
            clientId:clientId.id,
            taskTypes,
            defaultFTE,
            hierarchy:hierarchy.id,
            username,
            password,
            userId
        }
        let res= await dispatch(actionProyek.postProyek(data))
        if(res){
            props.modal_data.action()
        }
    }
    const handleDisable=()=>{
        if(clientId===null&&name===''&&defaultFTE===null&&hierarchy===null&&username===''&&password===''){
            return true
        }else{
            return false
        }
    }
    const deleteTaskTypes=(chip,index)=>{
        let filter=state.taskTypes.filter((data)=>{
            return data!==chip
        })
        setstate({...state,taskTypes:filter})
    }
    const onChangeUsername=(username)=>{
        let result=username.replace(/\s/g,'').toLowerCase()
        setstate({...state,username:result})
    }
    const onChangePassword=(password)=>{
        let result=password.replace(/\s/g,'').toLowerCase()
        setstate({...state,password:result})
    }
    const {classes}=props
    return (
        <div>
             <TextField
                label={<>Nama Proyek</>}
                variant='outlined'
                value={name}
                size='small'
                className={classes.textField}
                onChange={(e)=>setstate({...state,name:e.target.value})}
            />
            <div className='div-flex div-space-between div-align-center'>
                <div style={{width:'75%'}}>
                    <AutoCompleteSelect
                        onChange={(event,value)=>setstate({...state,clientId:value})}
                        options={client.client_list_for_dropdown}
                        value={clientId}
                        getOptionLabel={(option) => option.text}
                        label="Pilih Klien"
                        // multiple
                        // disabled={handleDisable()}
                    />
                </div>
                <Button onClick={addKlient} className='btn-remove-capital btn-text' variant="text" size="small" color="primary">Tambah Klien</Button>
            </div>
            <ChipInput
                label="Task Types"
                value={state.taskTypes}
                onAdd={(chip) =>setstate({...state,taskTypes:[...state.taskTypes,chip]})}
                // onAdd={(chip) =>console.log('chip', chip)}
                onDelete={(chip, index) => deleteTaskTypes(chip,index)}
                variant="outlined"
                className={classes.textField}
                // disabled={handleDisable()}

            />
            <InputMask
                        maskChar={null}
                        mask="9.99"
                        onChange={(e)=>setstate({...state,defaultFTE:e.target.value})}
                        value={state.defaultFTE}
                    >
                    {() =>
                         <TextField
                            label="Default FTE"
                            variant='outlined'
                            size='small'
                            className={classes.textField}
                            
                        />
                    }
                    </InputMask>

            
            <div style={{width:'100%',marginBottom:10}}>
                <AutoCompleteSelect
                    onChange={(event,value)=>setstate({...state,hierarchy:value})}
                    options={proyek.hierarchy_list_for_dropdown}
                    value={hierarchy}
                    getOptionLabel={(option) => option.text}
                    label="Hirarki Tugas"
                    // disabled={handleDisable()}
                />
            </div>
            <TextField
                label="Username Akses"
                variant='outlined'
                value={state.username}
                size='small'
                className={classes.textField}
                onChange={(e)=>onChangeUsername(e.target.value)}
            />
            {general.error_msg!==null&&<p style={{margin:0,marginBottom:20,color:'red'}}>{general.error_msg}</p>}
            <TextField
                label="Password"
                variant='outlined'
                value={state.password}
                size='small'
                className={classes.textField}
                onChange={(e)=>onChangePassword(e.target.value)}
            />
            <div style={{textAlign:'right'}}>
                <Button size="small" onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
