import * as actionTypes from 'redux/constants/sa'
const initialState={
    client_list:[],
    client_pagination:null,
    client_filter:{
        industry:[]
    },

    client_text_id:[],
    client_detail:{
        address:'',
        company:'',
        credit:0,
        id:0,
        industry:null,
        logoURL:null,
        phone:''
    },
    client_admin:[],
    history_credit:[],
    list_proyek:[],
    pagination:null
}
export default (state=initialState,action)=>{

    switch (action.type) {
        
        case actionTypes.SET_SA:
            return{
                ...state,
                [Object.keys(action.payload)]:Object.values(action.payload)[0]
            }
    
            default:

                return state
    }
}