import React,{useState} from 'react'
import Layout from 'components/Layouts'
import { createTheme,MuiThemeProvider } from '@material-ui/core'
import List from './list'
import Detail from './detail/detail'
import './style.css'
import Peta from './peta/peta'
const themeButton = createTheme({ 
    palette: { 
        primary: {
            main:'#00a1ed',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#4cc614',
            contrastText: '#FFFFFF',
        }
    } 
})
export default function Index({tabs='list',...props}) {
    const [tab, settab] = useState(tabs)
    const tabToggle=(key)=>{
        settab(key)
    }
    return (
        <Layout>
            <MuiThemeProvider theme={themeButton}>
                {tab==='list'&&<List tab={tab} tabToggle={tabToggle} {...props} />}
                {tab==='detail'&&<Detail tab={tab} tabToggle={tabToggle} {...props} />}
                {tab==='peta'&&<Peta tab={tab} tabToggle={tabToggle} {...props} />}
            </MuiThemeProvider>
        </Layout>
    )
}
