import React from 'react'
import {withRouter,useLocation } from 'react-router-dom'
function Index(props) {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
      }
    const query = useQuery();
    React.useEffect(() => {
        let act=query.get('act')
        if(act==='wla'){
            window.location.assign('/wla')
            
        }
        if(act==='talenta'){
            window.location.assign('/talenta')
        }
    }, [])
    console.log('pd',query.get('act'))
    return (
        <div>
            
        </div>
    )
}

export default withRouter(Index)
