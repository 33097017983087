import React, { useEffect } from 'react'
import TextField from 'components/TextField'
import InputMask from 'react-input-mask'
import AutoCompleteSelect from 'components/Select'
import { handleFile,getBase64 } from 'components/handleFile'
import { Button } from '@material-ui/core'
import avadefault from 'assets/icon/logo-empty.png'
import Close from 'assets/icon/close.svg'
import { TableRow,TableCell } from '@material-ui/core'
import ListProject from '../../talenta/Proyek/list'
import DataTable from 'components/DataTable'
import * as actionGeneral from 'redux/actions/general'
import SearchTable from 'components/SearchTable'

import { useSelector,useDispatch } from 'react-redux'
import * as actionSa from 'redux/actions/sa'
import _ from 'lodash'
import Riwayat_kredit from './riwayat_kredit'
import List_talent from './list_talent'
const RenderHead=(props)=>(
    <div className='div-flex div-space-between div-align-center'>
        <p className='semi-bold-without-margin'>Klien Admin</p>
        <Button disabled={props.handleDisable()} onClick={props.addAdmin} color="primary" className='btn-remove-capital' variant="text">Tambah Admin Client</Button>

    </div>
)
export default function Detail(props) {
    const dispatch = useDispatch()
    const sa = useSelector(state => state.sa)
    const client = useSelector(state => state.client)
    const [state, setstate] = React.useState({
        name:'',
        industry:null,
        phone:0,
        address:'',
        logo_url:null,
        logo_file:null,
        file_name:'',
        kredit:0
    })
    const [search, setsearch] = React.useState('')
    
    let {client_detail,client_admin}=sa
    let {list_industry}=client
    let {tab,tabToggle}=props
    React.useEffect(()=>{
        if(tab!=='add'){
            getDetailClient()
            dispatch(actionSa.getAdminClient(client_detail.id))
            setstate({
                ...state,
                logo_url:client_detail.logoURL
            })
        }
        
    },[])
    const getDetailClient=()=>{
        dispatch(actionSa.getDetailClient(client_detail.id))
    }
    const onClickSave=async ()=>{
        let res
        let fd= new FormData()
        fd.append('Id',client_detail.id)
        fd.append('Company',client_detail.company)
        fd.append('IndustryId',client_detail.industry.id)
        fd.append('Phone',client_detail.phone)
        fd.append('Address',client_detail.address)
        fd.append('Credit',client_detail.credit)
        fd.append('Files',state.logo_file)
        fd.append('UserId',props.profile.id)
        if(tab==='detail'){
            tabToggle('edit')
        }else if(tab==='edit'){
            
            res=await dispatch(actionSa.putClient(fd))
        }else{
            res=await dispatch(actionSa.addClient(fd))

        }
        if(res){
            tabToggle('detail')
        }
    }
    const onChange=(e)=>{
        let {name,value}=e.target
        dispatch(actionSa.setSa({client_detail:{...client_detail,[name]:value}}))
    }
    const onChangeFile=(evt)=>{
        let handle=handleFile(evt)
        if(handle.file_error===null){
            setstate({...state,logo_file:handle.file,logo_url:URL.createObjectURL(handle.file),file_name:handle.file_name})
            
        }
    }
    const handleDisable=()=>{
        if(tab==='detail'){
            return true
        }else{
            return false
        }
    }
    const afterPostAdmin=()=>{
        dispatch(actionSa.getAdminClient(client_detail.id))

    }
    const addAdmin=()=>{
        dispatch(actionGeneral.modalToggle({
            modal_open: true,
            modal_title: `Tambah Admin`,
            modal_component: "sa_add_admin",
            modal_size:400,
            modal_data:{
                client_admin:client_admin,
                afterPostAdmin:()=>afterPostAdmin()
            },
            modal_action:'sa_add_admin'
        }))
    }
    const action_reset=(data)=>{
        dispatch(actionSa.resetAdmin(data.userId))
    }
    const resetPassword=(data)=>{
        dispatch(actionGeneral.modalToggle({ 
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Admin',
                action:()=>action_reset(data),
                msg:`<p>Apakah anda yakin mengreset ${data.name} </p>`
            },
            modal_action:'reset_admin'
        }))
    }
    const action_delete=async (data)=>{
        let filter_admin=client_admin.filter((d)=>{
            return d.userId!==data.userId
        })
        let payload={
            userId:props.profile.id,
            clientId:client_detail.id,
            admins:filter_admin
        }
        let res=await dispatch(actionSa.postAdmin(payload))
        if(res){
            dispatch(actionSa.getAdminClient(client_detail.id))

        }
    }
    const deleteAdmin=(data)=>{
        dispatch(actionGeneral.modalToggle({ 
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Admin',
                userId:data.id,
                clientId:data.clientId,
                action:()=>action_delete(data),
                msg:`<p>Apakah anda yakin menghapus ${data.name} </p>`
            },
            modal_action:'delete_admin'
        }))
    }
    return (
        <div>
            <div className='head-section'>
                <h4><b>Detail Profile</b></h4>
                <div className='div-flex'>
                <Button onClick={()=>props.tabToggle('list')} className='btn-remove-capital btn-rounded' variant='outlined' color="primary">Kembali</Button>&nbsp;&nbsp;&nbsp;
                <Button onClick={onClickSave} className='btn-remove-capital btn-rounded' variant='contained' color="primary">{props.tab==='detail'?'Edit':'Save'}</Button>
                </div>
                
            </div>
            <div className='card-content'>
                <div className='div-flex div-space-between' style={{padding:20,gap:30,alignItems:'flex-start'}}>
                    <div style={{width:'50%'}}>
                        <p className='semi-bold'>Informasi Perusahaan Klien</p>
                        <TextField
                            label="Nama Perusahan"
                            onChange={onChange}
                            value={client_detail.company}
                            color='primary'
                            variant='outlined'
                            size='small'
                            name='company'
                            style={{marginBottom:20}}
                            disabled={handleDisable()}
                        />
                        
                        <AutoCompleteSelect
                            onChange={(event,value)=>dispatch(actionSa.setSa({client_detail:{...client_detail,industry:value}}))}
                            options={list_industry}
                            value={client_detail.industry}
                            getOptionLabel={(option) => option.text}
                            label="Industry"
                            disableClearable
                            disabled={handleDisable()}
                        />  
                        <InputMask
                                mask="9999-9999-9999-9999"
                                maskChar={null}
                                onChange={onChange}
                                value={client_detail.phone}
                                disabled={handleDisable()}
                            >
                            {() =>
                            <TextField
                                label="No. Telpon"
                                // onChange={onChange}
                                // value={target.status}
                                color='primary'
                                variant='outlined'
                                size='small'
                                disabled={handleDisable()}

                                name='phone'
                                style={{marginBottom:20}}
                            />}
                        </InputMask>
                        <TextField
                            label="Alamat"
                            onChange={onChange}
                            value={client_detail.address}
                            color='primary'
                            variant='outlined'
                            size='small'
                            name='address'
                            multiline
                            style={{marginBottom:20}}
                            disabled={handleDisable()}
                        />
                        <p className='semi-bold'>Kredit</p>
                        <TextField
                            label="Kredit"
                            onChange={onChange}
                            type="number"
                            value={client_detail.credit}
                            color='primary'
                            variant='outlined'
                            size='small'
                            name='credit'
                            style={{marginBottom:20}}
                            disabled={handleDisable()}
                        />
                    </div>
                    <div style={{width:'50%'}}>
                        <p className='semi-bold'>Informasi Perusahaan Klien</p>
                        <div className='div-flex div-align-center' style={{flexDirection:'column',justifyContent:'center'}}>
                            <img src={state.logo_url!==""&&state.logo_url!==null?state.logo_url:avadefault} style={{width:100,height:100,marginBottom:10,objectFit:'cover'}}/>
                            <Button disabled={handleDisable()} component="label"  onClick={null} className='btn-remove-capital ' variant='text' color="primary">
                                Upload Logo
                                <input
                                    type="file"
                                    style={{display:'none'}}
                                    onChange={onChangeFile}
                                />
                            </Button>
                            
                        </div>
                        <DataTable
                            head={['No.','Username / Email','Aksi']}
                            withoutCard={true}
                            body={()=>{
                                return client_admin.map((data,i)=>(
                                    <TableRow key={i} data-testid="proyek-item">
                                        <TableCell style={{width:10}}>{i+1}</TableCell>
                                        <TableCell>{data.email}</TableCell>
                                        <TableCell  style={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                            <p onClick={()=>resetPassword(data)} style={{margin:0,cursor:'pointer',color:'#00a1ed',fontWeight:'bold',fontSize:12,}}>Reset Password</p>
                                            <img src={Close} onClick={()=>deleteAdmin(data)} className='icon-action'/>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }}
                            cardHead={<RenderHead handleDisable={handleDisable} addAdmin={addAdmin}/>}
                            loading={false}
                            pagination={null}
                            togglePagination={null}
                        />
                    </div>
                </div>
            </div>
            <br/>
            {tab!=='add'&&<Riwayat_kredit {...props}/>}
            {tab!=='add'&&<List_talent {...props}/>}
            {/* <ListProject {...props}/> */}
        </div>
    )
}
