import React,{useState,useEffect} from 'react'
import {Button,TextField,FormControl,InputLabel,Select,MenuItem} from '@material-ui/core'
import AutoCompleteSelect from 'components/Select'
import {useSelector,useDispatch} from 'react-redux'
import * as proyekAction from 'redux/actions/proyek'
export default function Filter_rbk(props) {
    const [state, setstate] = useState({
        consultantIds:[],
        batch:0,
        kodesdm:null,
        sdm:0,
        kodeneed:null,
        need:0,
        surplus:0,
        status:0,
    })
    const dispatch = useDispatch()
    const proyek = useSelector(state => state.proyek)
    const {recap_filter,kodesdm_list,list_surplus,list_status,proyek_detail}=proyek

    useEffect(() => {
        setstate({
            consultantIds:recap_filter.consultantIds,
            batch:recap_filter.batch,
            kodesdm:recap_filter.kodesdm,
            sdm:recap_filter.sdm,
            kodeneed:recap_filter.kodeneed,
            need:recap_filter.need,
            surplus:recap_filter.surplus,
            status:recap_filter.status,
        })
    }, [])
    const konsultan = useSelector(state => state.konsultan)
    const {consultantIds,batch,kodesdm,sdm,kodeneed,need,surplus,status}=state
    const {classes}=props
    const onClickSimpan=async ()=>{
        let new_konsultan=[]
        consultantIds.map((data)=>{
            new_konsultan.push(data.id)
        })
       

        let res=await dispatch(proyekAction.getRecapList(`/${proyek_detail.projectId}/${new_konsultan.length>0?new_konsultan.join(','):0}/${batch}/${kodesdm===null?0:kodesdm}/${sdm}/${kodeneed===null?0:kodeneed}/${need}/${surplus===0?0:surplus.id}/${status===0?0:status.id}/1/10/*`))
        props.modalToggleReset()

        if(res){
            dispatch(proyekAction.setRecapFilter({consultantIds:consultantIds}))
            dispatch(proyekAction.setRecapFilter({batch:batch}))
            dispatch(proyekAction.setRecapFilter({kodesdm:kodesdm}))
            dispatch(proyekAction.setRecapFilter({sdm:sdm}))
            dispatch(proyekAction.setRecapFilter({kodeneed:kodeneed}))
            dispatch(proyekAction.setRecapFilter({need:need}))
            dispatch(proyekAction.setRecapFilter({surplus:surplus}))
            dispatch(proyekAction.setRecapFilter({status:status}))

        }
    }
    const onClickReset=()=>{
        setstate({
            consultantIds:[],
            batch:0,
            kodesdm:null,
            sdm:0,
            kodeneed:null,
            need:0,
            surplus:0,
            status:0,
        })
        // dispatch(proyekAction.resetRecapFilter())
    }
    return (
        <div>
           <div style={{width:'100%'}}>
                <AutoCompleteSelect
                    onChange={(event,value)=>setstate({...state,consultantIds:value})}
                    options={konsultan.konsultan_list_drowdown}
                    value={state.consultantIds}
                    getOptionLabel={(option) => option.text}
                    label="Konsultan"
                    multiple
                />
            </div>
            <TextField
                label="Batch"
                variant='outlined'
                value={state.batch}
                size='small'
                className={classes.textField}
                onChange={(e)=>setstate({...state,batch:e.target.value})}
            />
            <p className='semi-bold'>Jumlah SDM</p>
            <div className='div-flex div-space-between'>
                <div style={{width:'35%'}}>
                    <FormControl  size='small' variant='outlined' className={classes.textField}>
                        <InputLabel id="demo-simple-select-label">Operator</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.kodesdm}
                            onChange={(e)=>setstate({...state,kodesdm:e.target.value})}
                            labelWidth={75}
                        >
                            {kodesdm_list.map((data,i)=>(
                                <MenuItem key={i} value={data.id}>{data.text}</MenuItem>

                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div style={{width:'60%'}}>
                    <TextField
                        label="Input Jumlah"
                        variant='outlined'
                        value={state.sdm}
                        size='small'
                        type='number'
                        className={classes.textField}
                        onChange={(e)=>setstate({...state,sdm:e.target.value})}
                    />
                </div>
            </div>
            <p className='semi-bold'>Kebutuhan SDM</p>
            <div className='div-flex div-space-between'>
                <div style={{width:'35%'}}>
                <FormControl  size='small' variant='outlined' className={classes.textField}>
                        <InputLabel id="demo-simple-select-label">Operator</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={state.kodeneed}
                            onChange={(e)=>setstate({...state,kodeneed:e.target.value})}
                            labelWidth={75}
                        >
                            {kodesdm_list.map((data,i)=>(
                                <MenuItem key={i} value={data.id}>{data.text}</MenuItem>

                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div style={{width:'60%'}}>
                    <TextField
                        label="Input Jumlah"
                        variant='outlined'
                        type='number'
                        value={state.need}
                        size='small'
                        className={classes.textField}
                        onChange={(e)=>setstate({...state,need})}
                    />
                </div>
            </div>
            <div style={{width:'100%'}}>
                <AutoCompleteSelect
                    onChange={(event,value)=>setstate({...state,surplus:value})}
                    options={[{id:0,text:'Show All'},...list_surplus]}
                    value={state.surplus}
                    getOptionLabel={(option) => option.text}
                    label="Surplus/Deficit"
                />
            </div>
            <div style={{width:'100%'}}>
                <AutoCompleteSelect
                    onChange={(event,value)=>setstate({...state,status:value})}
                    options={[{id:0,text:'All Status'},...list_status]}
                    value={state.status}
                    getOptionLabel={(option) => option.text}
                    label="Status"
                />
            </div>
            <div style={{textAlign:'right'}}>
                <Button size="small" onClick={onClickReset} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Reset</Button>
                &nbsp;&nbsp;
                <Button size="small" onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Simpan</Button>
            </div>
        </div>
    )
}
