import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
  } from "react-router-dom";
  import React,{useEffect} from 'react'

import Login from 'views/login'

import Cookie from 'universal-cookie'
import {  useDispatch } from "react-redux";
import {logout} from 'redux/actions/auth'

import Client from 'views/client'
import Konsultant from 'views/konsultant'
import Abk from 'views/abk'
import Responden from 'views/responden'
import TalentaLogin from 'views/talenta/Login'
import Kegiatan from 'views/talenta/Proyek'
import Index from 'views'
import SuperAdminClient from 'views/superadmin/Client'
import SuperAdminProyek from 'views/superadmin/Proyek'
const cookie=new Cookie()
const Logout=()=>{
    const dispatch=useDispatch()
    useEffect(()=>{
        dispatch(logout())

    },[])
    return(
        <Redirect to='/wla'/>
    )
}
const indexRouter=(props)=>{
    const token=cookie.get('login_cookie')
    const profile=cookie.get('profile_cookie')
   
    const isHaveToken=()=>{
        if(token!==undefined &&profile!==undefined){
            if(profile.roleId!==3){
                return(
                    <>
                        <Route path='/wla/client' exact render={()=>(<Client token={token} profile={profile}/>)} />
                        <Route path='/wla/konsultan' exact render={()=>(<Konsultant token={token} profile={profile}/>)} />
                        <Route path='/wla/analisis-beban-kerja' exact render={()=>(<Abk token={token} profile={profile}/>)} />
                        <Route path='/wla/responden' exact render={()=>(<Responden token={token} profile={profile}/>)} />


                        <Route path='/talenta/mapping' exact render={()=>(<Kegiatan token={token} profile={profile}/>)} />

                    </> 
                )
            }else{
                return(
                    <>
                        <Route path='/sa/client' exact render={()=>(<SuperAdminClient token={token} profile={profile}/>)} />
                        <Route path='/sa/talenta/mapping' exact render={()=>(<SuperAdminProyek token={token} profile={profile}/>)} />

                    </>
                )
            }
        }
    }
    return(
        <Router>
        <Switch>
            <Route path='/' exact component={Index}/>
            <Route path='/wla' exact component={Login}/>
            <Route path='/talenta' exact component={TalentaLogin}/>
            {isHaveToken()}
        </Switch>
        </Router>
    )
}
  export default indexRouter
  