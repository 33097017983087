import React, { useState } from 'react'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TableCell,TableRow,TableBody,TableContainer,Paper} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import * as actionGeneral from 'redux/actions/general'
import { useSelector,useDispatch } from 'react-redux'
import * as actionTalenta from 'redux/actions/talenta'
import AutoCompleteSelect from 'components/Select'
import _ from 'lodash'
import MultiSelect from 'components/MultiSelect'
import ReactSelect from 'components/ReactSelect'
const useStyles = makeStyles(theme => ({
    paper: {
        boxShadow:'unset'
      },
    //   table: {
    //     "& .MuiTableCell-root": {
    //         borderBottom: '1px solid #ececec',
    //       },
    //   }
}));

export default function Proyek_norma(props) {
    const talenta = useSelector(state => state.talenta)
    const classes=useStyles()
    let {profile,getNormaByRange,getNormaByCategory,norma_range,norma_category}=props
    const dispatch = useDispatch()
    const [range_edit, setrange_edit] = useState(false)
    const [category_edit, setcategory_edit] = useState(false)
    const afterPost=()=>{
        getNormaByRange()
        getNormaByCategory()
        dispatch(actionGeneral.modalToggleReset())
    }
    const configNorma=async ()=>{
        let klasifikasi=await dispatch(actionTalenta.getOptionXY(talenta.project_info.id))
        if(klasifikasi){
            dispatch(actionGeneral.modalToggle({
                modal_open: true,
                modal_title: `Konfigurasi Tipe Data Norma`,
                modal_component: "norma",
                modal_size:450,
                modal_type:'norma',
                modal_data:{
                    afterPost:()=>afterPost(),
                    klasifikasi_list:klasifikasi.data,
                    
                },
                modal_action:'norma'
            }))
        }
    }
    const onSaveRange=()=>{
        if(range_edit){
            let payload={
                min:[],
                max:[],
                norm:[],
                userId:profile.id
            }
            norma_range.rows.map((d)=>{
                payload.norm.push(d.norm)
                d.items.map((item,i)=>{
                    if(i%2===1){
                        payload.max.push(item)
                    }else{
                        payload.min.push(item)

                    }
                })
            })
            dispatch(actionTalenta.postNormaRange(payload))
            setrange_edit(false)
            // console.log(`norma_range`, payload)

        }else{
            setrange_edit(true)
        }
    }
    const onSaveCategory=()=>{
        if(category_edit){
            let payload={
                items:[],
                norm:[],
                userId:profile.id
            }
            
            norma_category.rows.map((d)=>{
                let new_c=[]
                d.cells.map((c)=>{
                    c.categories.map((ct)=>{
                        new_c.push(ct.value)
                    })
                    // new_c.push(c.categories.map((cc)=>cc.id))
                })
                payload.items.push({
                    normId:d.norm.id,
                    // ids:d.categories.map((d)=>d.id)
                    ids:new_c
                })
                payload.norm.push(d.norm)
            })
            dispatch(actionTalenta.postNormaCategory(payload))
            setcategory_edit(false)

            console.log(`payload`, payload)
        }else{
            setcategory_edit(true)
        }
    }
    const onKeyPress=(e)=>{
        if(e.which===13){
            e.preventDefault()
        }
    }
    const onInput=_.debounce(async (value,i1,i2,key)=>{
        // console.log('key',key)
        // console.log(i1,i2,norma_range.rows)
        if(key==='min'&&i1!==norma_range.rows.length-1){
            norma_range.rows[i1+1].items[i2+1].number=parseInt(value===' ~'?0:value)
        }
        if(key==='max'&&i1!==0){
            norma_range.rows[i1-1].items[i2-1].number=parseInt(value===' ~'?0:value)

        }
        norma_range.rows[i1].items[i2].number=parseInt(value===' ~'?0:value)
        dispatch(actionTalenta.setTalenta({norma_range:norma_range}))
    },1000)
    const onInputNormNumber=(value,i)=>{
        norma_range.rows[i].norm.number=parseInt(value)
    }
    const onInputNormCategoryNumber=(value,i)=>{
        norma_category.rows[i].norm.number=parseInt(value)
    }
    const onSelect=async (value,a,i,i2,normId,dimId)=>{

        if(a.action==='create-option'){

            let payload={
                normId:normId,
                dimId:dimId,
                item:a.option.value
            }
            let new_value=value
            value.pop()
            let res=await dispatch(actionTalenta.postCategoryList(payload))
            if(res){
                new_value.push({label:res.data.text,value:res.data.id})
                // getNormaByCategory()
                norma_category.rows[i].cells[i2].categories=new_value
                dispatch(actionTalenta.setTalenta({norma_category:norma_category}))
            }
        }else{
            norma_category.rows[i].cells[i2].categories=value
            dispatch(actionTalenta.setTalenta({norma_category:norma_category}))
            // console.log(`value`, value)
        }
        
    }
    const renderOptions=(options,i1,i2)=>{
        let used_options=[]
        let new_options=[]
        norma_category.rows.map((r)=>{
        //    used_options.push(c)
            r.cells.map((c)=>{
                c.categories.map((ct)=>{
                    used_options.push(ct)
                })
            })
        })
        options.map((o)=>{
            let findIfUsed=used_options.filter((u)=>{
                return u.value===o.value
            })
            if(findIfUsed.length===0){
                new_options.push(o)
            }
        })
        // console.log(`new_options`, new_options,used_options)
        return new_options
    }
    const onRemove=(value,i,i2)=>{
        norma_category.rows[i].cells[i2].categories=value
        dispatch(actionTalenta.setTalenta({norma_category:norma_category}))
        // console.log(`e`, e)
    }
    const onCreateNew=()=>{
        
    }
    console.log(`norma_category`, norma_category)
    return (
        <div>
            <div className='head-section'>
                <div className='div-flex div-align-center' >
                    <h4 style={{fontWeight:'bold'}}>Norma</h4>
                </div>
                <div className='div-flex'>
                    <Button  onClick={configNorma}  className='btn-remove-capital btn-rounded' variant='contained' color="primary">Konfigurasi Norma</Button>
                </div>
            </div>
            {norma_range!==null&&<><div className='card-content'>
                <div className='card-table'>
                    <div className='card-table___head'>
                        <div className='div-flex div-align-center div-space-between'>
                            <p style={{margin:0,color:'#777777',fontWeight:600,fontSize:14}}>Nilai norma berdasarkan rentang nilai</p>
                            <Button onClick={onSaveRange} variant="text" className="btn-remove-capital" color="primary">
                                {range_edit?'Simpan':'Edit'}
                            </Button>
                        </div>
                    </div>
                    <br/>
                    <div className='card-table__content'>
                    <TableContainer className={classes.paper} component={Paper}>
                        <Table  className={classes.table}  size="small" aria-label="a dense table">
                            <TableHead >
                            <TableRow>
                                <TableCell style={{border:'1px solid rgba(224, 224, 224, 1)',}}>No.</TableCell>
                                <TableCell style={{border:'1px solid rgba(224, 224, 224, 1)',}}  align="center" >Nilai Norma</TableCell>
                                {norma_range.headers.map((d,i)=>(
                                    <TableCell key={i} style={{border:'1px solid rgba(224, 224, 224, 1)',}} colSpan={2} align="center" >{d}</TableCell>

                                ))}
                            </TableRow>
                            
                            </TableHead>
                            <TableBody>
                                {norma_range.rows.map((d,i)=>(
                                    <TableRow key={i}>
                                        <TableCell style={{border:'1px solid rgba(224, 224, 224, 1)',}}>{i+1}</TableCell>
                                        <TableCell suppressContentEditableWarning={true} onInput={(e)=>onInputNormNumber(e.currentTarget.textContent,i)} onKeyPress={onKeyPress} style={{border:'1px solid rgba(224, 224, 224, 1)'}} align="center" contentEditable={`${range_edit}`}>{d.norm.number}</TableCell>
                                        {d.items.map((item,i2)=>(
                                            <TableCell   key={i2} 
                                                style={{border:'1px solid rgba(224, 224, 224, 1)'}} align="left" >
                                                <span>{i2%2===1?'≤':'>'}</span>
                                                &nbsp;
                                                <span suppressContentEditableWarning={true} onInput={(e)=>onInput(e.currentTarget.textContent,i,i2,i2%2===1?'max':'min')} onKeyPress={onKeyPress}  contentEditable={`${range_edit}`}>{` ${i2%2===1&&item.number===0?'~':item.number}`}</span>
                                            </TableCell>

                                        ))}
                                        
                                    </TableRow>
                                ))}
                                
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </div>
                    <br/>
                    <p className='semi-bold'><span style={{color:'red'}}>*</span>&nbsp;&nbsp;Selain nilai di atas maka nilai norma akan 0</p>
                </div>
            </div>
            <br/></>
            }
            
            {norma_category!==null&&<><div className='card-content'>
                <div className='card-table'>
                    <div className='card-table___head'>
                        <div className='div-flex div-align-center div-space-between'>
                            <p style={{margin:0,color:'#777777',fontWeight:600,fontSize:14}}>Nilai norma berdasarkan Kategori</p>
                            <Button onClick={onSaveCategory} variant="text" className="btn-remove-capital" color="primary">
                                {category_edit?'Simpan':'Edit'}
                            </Button>
                        </div>
                    </div>
                    <br/>
                    <div className='card-table__content'>
                    <TableContainer  className={classes.paper} component={Paper}>
                        <Table  className={classes.table}  size="small" aria-label="a dense table">
                            <TableHead >
                            <TableRow>
                                <TableCell style={{border:'1px solid rgba(224, 224, 224, 1)',}}>No.</TableCell>
                                <TableCell style={{border:'1px solid rgba(224, 224, 224, 1)',}}  align="center" >Nilai Norma</TableCell>
                                {norma_category.dim.map((d,i)=>(
                                    <TableCell key={i} style={{border:'1px solid rgba(224, 224, 224, 1)',}}  align="center" >{d.text}</TableCell>
                                ))}
                                
                                
                            </TableRow>
                            
                            </TableHead>
                            <TableBody>
                                {norma_category.rows.map((d,i)=>(
                                    <TableRow key={i}>
                                        <TableCell style={{border:'1px solid rgba(224, 224, 224, 1)',}}>{i+1}</TableCell>
                                        <TableCell onInput={(e)=>onInputNormCategoryNumber(e.currentTarget.textContent,i)} suppressContentEditableWarning={true} style={{border:'1px solid rgba(224, 224, 224, 1)'}} align="center" contentEditable={`${category_edit}`}>{d.norm.number}</TableCell>
                                        {norma_category.dim.map((dim,i2)=>(
                                            <TableCell style={{border:'1px solid rgba(224, 224, 224, 1)',}} align="left" >
                                                {/* <MultiSelect
                                                    options={dim.options}
                                                    selectedValues={d.cells[i2].categories}
                                                    onSelect={(e)=>onSelect(e,i,i2)}
                                                    onRemove={(e)=>onRemove(e,i,i2)}
                                                    disable={!category_edit}
                                                    
                                                /> */}
                                                <ReactSelect
                                                    options={renderOptions(dim.options,i,i2)}
                                                    value={d.cells[i2].categories}
                                                    handleChange={(e,a)=>onSelect(e,a,i,i2,d.norm.id,dim.id)}
                                                    isDisabled={!category_edit}

                                                />
                                            </TableCell>
                                        ))}
                                        
                                        
                                        
                                    </TableRow>
                                ))}
                                
                            </TableBody>
                        </Table>
                    </TableContainer>
                    </div>
                    <br/>
                    <p className='semi-bold'><span style={{color:'red'}}>*</span>&nbsp;&nbsp;Selain nilai di atas maka nilai norma akan 0</p>
                </div>
            </div>
            <br/></>
            }
            
            {(norma_range===null&&norma_category===null)&&<div className='card-content' style={{display:'flex',alignItems:'center',justifyContent:'center',height:100}}>
                <p className='semi-bold'><b>Konfigurasi tipe data norma, <span onClick={configNorma} style={{color:'rgb(0, 161, 237)',cursor:'pointer'}}>Konfigurasi Norma</span></b></p>
            </div>}
        </div>
    )
}
