import React,{useState,useEffect} from 'react'
import {Button,TextField,FormControl,InputLabel,Select,MenuItem} from '@material-ui/core'
import AutoCompleteSelect from 'components/Select'
import {useDispatch,useSelector} from 'react-redux'
import {postJabatan,postJabatanDetail} from 'redux/actions/proyek'
export default function Jabatan(props) {
    const dispatch = useDispatch()
    const proyek = useSelector(state => state.proyek)
    let {proyek_detail}=proyek
    const [state, setstate] = useState({
        hierarchy:null,
        kode_unit:'',
        jenjang:'',
        unit_kerja:'',
        nama_jabatan:'',
        jumlah_pegawai:'',
        lokasi_kerja:'',
        id:0,
        batch:''
    })
    const {classes,modal_data,modal_action}=props
    const {hierarchy,unit_kerja,nama_jabatan,jumlah_pegawai,lokasi_kerja,kode_unit,jenjang,batch}=state
    useEffect(() => {
        if(modal_action==='edit_jabatan'){
            const {recap_detail}=modal_data
            let new_hirarki=proyek.hierarchy_list_for_dropdown.filter((data)=>{
                return data.id===recap_detail.hierarchy
            })
            setstate({
                hierarchy:new_hirarki[0],
                kode_unit:recap_detail.shortname,
                jenjang:recap_detail.jobLevel.text,
                unit_kerja:{id:recap_detail.workUnitId,text:recap_detail.workUnit},
                nama_jabatan:recap_detail.jobTitle,
                jumlah_pegawai:recap_detail.sdm,
                lokasi_kerja:recap_detail.location,
                id:recap_detail.jobDetailId,
                batch:recap_detail.batch
            })
        }
        
    }, [])
    const onClickSimpan=async ()=>{
        let data={
            id:state.id,
            projectId:proyek_detail.projectId,
            hierarchy:hierarchy.id,
            workUnit:unit_kerja,
            shortname:kode_unit,
            jobTitle:nama_jabatan,
            jobLevel:jenjang,
            employees:parseInt(jumlah_pegawai),
            location:lokasi_kerja,
            userId:props.profile.id,
            batch:batch
        }
        let data2={
            jobDetailId:state.id,
            projectId:proyek_detail.projectId,
            hierarchy:hierarchy.id,
            workUnit:unit_kerja.id,
            shortname:kode_unit,
            jobTitle:nama_jabatan,
            jobLevel:jenjang,
            employees:parseInt(jumlah_pegawai),
            location:lokasi_kerja,
            userId:props.profile.id,
            batch:batch
        }
        let res
        if(modal_action!=='edit_jabatan'){
            res= await dispatch(postJabatan(data))
        }else{
            res= await dispatch(postJabatanDetail(data2))
        }
        
        if(res){
            props.modal_data.action()
            // props.modal_data.action2()
        }
    }
    
    console.log(`state`, state)
    return (
        <div>
            
            <TextField
                label="Kode Unit Kerja"
                variant='outlined'
                value={state.kode_unit}
                size='small'
                className={classes.textField}
                onChange={(e)=>setstate({...state,kode_unit:e.target.value})}
            />
            {modal_action==='edit_jabatan'?
                <div style={{width:'100%'}}>
                    <AutoCompleteSelect
                        onChange={(event,value)=>setstate({...state,unit_kerja:value})}
                        options={proyek.work_unit}
                        value={unit_kerja}
                        getOptionLabel={(option) => option.text}
                        label="Unit Kerja"
                        // disabled={handleDisable()}
                    />
                </div>
            :
                <TextField
                    label="Unit Kerja"
                    variant='outlined'
                    value={state.unit_kerja}
                    size='small'
                    className={classes.textField}
                    onChange={(e)=>setstate({...state,unit_kerja:e.target.value})}
                />
            }
            
            
             <TextField
                label="Jenjang Jabatan"
                variant='outlined'
                value={state.jenjang}
                size='small'
                className={classes.textField}
                onChange={(e)=>setstate({...state,jenjang:e.target.value})}
            />
             <TextField
                label="Nama Jabatan"
                variant='outlined'
                value={state.nama_jabatan}
                size='small'
                className={classes.textField}
                onChange={(e)=>setstate({...state,nama_jabatan:e.target.value})}
            />
            <div style={{width:'100%'}}>
                <AutoCompleteSelect
                    onChange={(event,value)=>setstate({...state,hierarchy:value})}
                    options={proyek.hierarchy_list_for_dropdown}
                    value={hierarchy}
                    getOptionLabel={(option) => option.text}
                    label="Hirarki Tugas"
                    // disabled={handleDisable()}
                />
            </div>
             <TextField
                label="Jumlah Pegawai"
                variant='outlined'
                value={state.jumlah_pegawai}
                size='small'
                type='number'
                className={classes.textField}
                onChange={(e)=>setstate({...state,jumlah_pegawai:e.target.value})}
            />
             <TextField
                label="Lokasi Kerja"
                variant='outlined'
                value={state.lokasi_kerja}
                size='small'
                className={classes.textField}
                onChange={(e)=>setstate({...state,lokasi_kerja:e.target.value})}
            />
            {modal_action==='edit_jabatan'&&<TextField
                label="Batch"
                variant='outlined'
                value={state.batch}
                size='small'
                className={classes.textField}
                onChange={(e)=>setstate({...state,batch:e.target.value})}
            />}
            <div style={{textAlign:'right'}}>
                <Button size="small" onClick={onClickSimpan} color="primary" variant="contained" className='btn-remove-capital btn-rounded' >Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
