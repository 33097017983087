import React,{useState} from 'react'
import Select from 'components/Select'
import { Button } from '@material-ui/core'
import _ from 'lodash'
import { useSelector,useDispatch } from 'react-redux'
import TextField from 'components/TextField'
import {postNormaProject} from 'redux/actions/talenta'
export default function Norma(props) {
    let {klasifikasi_list,filename}=props.modal_data
    const dispatch = useDispatch()
    console.log(`klasifikasi_list`, klasifikasi_list)
    const talenta = useSelector(state => state.talenta)
    const general = useSelector(state => state.general)
    const [state, setstate] = useState({
        list:klasifikasi_list,
        range:[],
        category:[],
        total:0
    })
    const onChangeSelect=(value,name,reason)=>{
        let new_list
        if(Array.isArray(value)){
            if(reason==='select-option'){
                value.map((v)=>{
                    new_list=state.list.filter((d)=>{
                        return d.id!==v.id
                    })
                })
            }else{
                if(_.isEmpty(value)){
                    new_list=[...state[name],...state.list]
                }else{
                    value.map((v)=>{
                        new_list=klasifikasi_list.filter((d)=>{
                            return d.id!==v.id
                        })
                        
                    })
                }
            }
        }else{
            if(value){
                new_list=state.list.filter((d)=>{
                    return d.id!==value.id
                })
            }else{
                new_list=[state[name],...state.list]
            }
        }
        setstate({...state,[name]:value,list:new_list})
    }
    const handleDisable=()=>{
        return false
    }
    const onSave=async ()=>{
        let data={
            projectId:talenta.project_info.id,
            range:state.range.map((d)=>d.id),
            category:state.category.map((d)=>d.id),
            total:parseInt(state.total),
            userId:props.profile.id
        }
        let res=await dispatch(postNormaProject(data))
        if(res){
            props.modal_data.afterPost()
        }
    }
    return (
        <div>
            <Select
                onChange={(event,value,reason)=>onChangeSelect(value,'range',reason)}
                options={state.list}
                value={state.range}
                getOptionLabel={(option) => option.text}
                label="Norma berdasarkan rentang nilai"
                multiple
                filterSelectedOptions
                style={{marginBottom:10}}
            />
            <Select
                onChange={(event,value,reason)=>onChangeSelect(value,'category',reason)}
                options={state.list}
                value={state.category}
                getOptionLabel={(option) => option.text}
                label="Norma berdasarkan kategori"
                multiple
                filterSelectedOptions
                style={{marginBottom:10}}
            />
            <TextField
                label="Jumlah nilai norma"
                onChange={(e)=>setstate({...state,total:e.target.value})}
                value={state.total}
                color='primary'
                variant='outlined'
                size='small'
                name='total'
                style={{marginBottom:20}}
            />
            <div style={{textAlign:'right'}}>
                <Button onClick={onSave} disabled={handleDisable()} className='btn-rounded btn-remove-capital' color="primary" variant="contained" size="small">Simpan</Button>
            </div>
            <br/>
        </div>
    )
}
