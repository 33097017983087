import React,{useState,useEffect,useRef} from 'react'
import { Button } from '@material-ui/core'
import AutoCompleteSelect from 'components/Select'
import { InputAdornment } from '@material-ui/core';
import TextField from 'components/TextField'
import IconCard from 'assets/icon/IconCard.png'
import ChevronBottom from 'assets/icon/chevron-bottom.png'
import Modal from '../modal'
import Titik from './titik';
import Persentase from './persentase'
import SearchTable from 'components/SearchTable';
import Nama from './nama';
import _ from 'lodash'
import { useSelector,useDispatch } from 'react-redux';
import * as actionTalenta from 'redux/actions/talenta'
import * as actionGeneral from 'redux/actions/general'
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import OneTalentBlack from 'assets/image/OneTalentBlack.png'
const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref}>
        <div className='div-flex sebaran-wrapper' style={{position:'relative'}}>
            <img src={"https://cors.bridged.cc/"+props.profile.logo_url} style={{width:100,position:'absolute'}}/>
            <div style={{marginLeft:150}}>
                <p style={{margin:0,fontSize:24,maxWidth:450}}>Peta sebaran persentase karyawan <br/>{props.profile.company}</p>
                {props.renderShowFilter()>0&&
                <div >
                    <div style={{display:'flex',flexWrap:'wrap',minWidth:200,maxWidth:500}}>
                        <b className='pipeline-filterby'>Berdasarkan </b>
                        {props.filter_by_field_value_with_id.map((d,i)=>{
                            if(d.value.length>0){
                                return(
                                    <p className='pipeline-filterby'>{d.filter.text} : {d.value.map((v)=>v.text).join(' , ')}&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                )
                            }

                        })}
                    </div>
                </div>}
            </div>
            <img src={OneTalentBlack} style={{width:130,right:100,position:'absolute'}}/>

        </div>
        <br/>
       {props.jenis_sebaran==='titik'&&<Titik filter_by_field_value={props.filter_by_field_value} project_info={props.project_info} getSebaranDots={props.getSebaranDots} peta_sebaran={props.peta_sebaran_dots} {...props}/>}
        {props.jenis_sebaran==='persentase'&&<Persentase filter_by_field_value={props.filter_by_field_value} project_info={props.project_info} getSebaranPersentase={props.getSebaranPersentase} peta_sebaran={props.peta_sebaran_persentase} {...props}/>}
        {props.jenis_sebaran==='nama'&&<Nama filter_by_field_value={props.filter_by_field_value} project_info={props.project_info} getSebaranDots={props.getSebaranDots} peta_sebaran={props.peta_sebaran_dots} {...props}/>}
    </div>
  ));
export default function Peta_sebaran(props) {
    const componentRef = useRef();
    // const [modal_jenis, setmodal_jenis] = useState(false)
    // const [jenis_sebaran, setjenis_sebaran] = useState('titik')
    const [search, setsearch] = useState('')
    const [download_visible, setdownload_visible] = useState(false)
    const talenta = useSelector(state => state.talenta)
    let {project_info,template_active,peta_sebaran_dots,peta_sebaran_persentase,filter_by_field_value_with_id,filter_by_field_value}=talenta
    
    let {searchToggle,filterMap,jenis_sebaran,setjenis_sebaran,modal_jenis,setmodal_jenis,getSebaranDots,jenisSebaranToggle,getSebaranPersentase,getReport}=props
    console.log(`props.profile`, props.profile)
    const downloadMap=()=>{
        if(jenis_sebaran!=='nama'){
            setdownload_visible(true)
            setTimeout(() => {
                exportComponentAsPNG(componentRef,{fileName:"Peta Sebaran"})
                setdownload_visible(false)
                
            }, 1000);
        }else{
            getReport()
        }
        
    }
    
    const renderValuePeta=()=>{
        if(jenis_sebaran==='titik'){
            return 'Peta sebaran titik Karyawan'
        }
        if(jenis_sebaran==='persentase'){
            return 'Peta sebaran persentase karyawan'
        }
        if(jenis_sebaran==='nama'){
            return 'Peta sebaran nama karyawan'
        }
    }
    const renderShowFilter=()=>{
        if(filter_by_field_value_with_id.length>0){
            let filter=filter_by_field_value_with_id.filter((d)=>{
                return d.value.length>0
            })
            if(filter.length>0){
                return true
            }else{
                return false
            }
        }else{
            return false
        }
    }
    console.log(`filter_by_field_value_with_id`, filter_by_field_value_with_id)
    return (
        <div>
            {modal_jenis&&<div onClick={()=>setmodal_jenis(false)} className='backdoor'></div>}

            <div className='head-section'>
                <h4><b>Peta Sebaran Talent Managemen</b></h4>
                <Button  onClick={()=>downloadMap()}  className='btn-remove-capital btn-rounded' variant='contained' color="primary">Download Map</Button>
            </div>
            <br/>
            <div>
                <div className='card-content' style={{padding:20}}>
                    <div className='div-flex div-space-between div-align-center'>
                        <div style={{width:350,position:'relative'}}>
                            <TextField
                                label=""
                                onChange={(e)=>null}
                                value={renderValuePeta()}
                                color='primary'
                                onFocus={()=>setmodal_jenis(true)}
                                variant='outlined'
                                readonly
                                size='small'
                                name='name'
                                InputProps={{
                                    startAdornment: <InputAdornment position="start"><img src={IconCard} style={{width:20}}/></InputAdornment>,
                                    endAdornment: <InputAdornment position="end"><img src={ChevronBottom} style={{width:13}}/></InputAdornment>,
                                }}
                            />
                            {modal_jenis&&<Modal
                                modal_title="Jenis peta sebaran"
                                modal_size={300}
                                modalToggleReset={()=>setmodal_jenis(false)}
                                body={
                                    <div >
                                        <div onClick={()=>jenisSebaranToggle('titik')} className={`jenis-peta ${jenis_sebaran==='titik'&&'peta-active'}`} style={{padding:10,cursor:'pointer'}}>
                                            <p style={{margin:0,fontWeight:'bold',fontSize:14}}>Peta sebaran titik Karyawan</p>
                                        </div>
                                        <div onClick={()=>jenisSebaranToggle('persentase')} className={`jenis-peta ${jenis_sebaran==='persentase'&&'peta-active'}`} style={{padding:10,cursor:'pointer'}}>
                                            <p style={{margin:0,fontWeight:'bold',fontSize:14}}>Peta sebaran persentase karyawan</p>
                                        </div>
                                        <div onClick={()=>jenisSebaranToggle('nama')} className={`jenis-peta ${jenis_sebaran==='nama'&&'peta-active'}`} style={{padding:10,cursor:'pointer'}}>
                                            <p style={{margin:0,fontWeight:'bold',fontSize:14}}>Peta sebaran nama karyawan</p>
                                        </div>
                                    </div>
                                }
                            />}
                        </div>
                        {jenis_sebaran==='nama'&&<SearchTable
                            {...props}
                            height={35}
                            borderRadius={10}
                            searchToggle={(value)=>searchToggle(value)}
                        />}
                        <div className='div-flex div-align-center'>
                            <div className='div-flex ' style={{alignItems:'flex-end'}}>
                                {renderShowFilter()&&
                                <div >
                                    <p className='pipeline-filterby'><b>Filter berdasarkan:</b></p>
                                    <div style={{display:'flex',flexWrap:'wrap',minWidth:200,maxWidth:200}}>
                                        {filter_by_field_value_with_id.map((d,i)=>{
                                            if(d.value.length>0){
                                                return(
                                                    <p className='pipeline-filterby'>{d.filter.text} : {d.value.map((v)=>v.text).join(' , ')}&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                                )
                                            }

                                        })}
                                    </div>
                                </div>}
                                <Button  onClick={()=>filterMap()}  className='btn-remove-capital' variant='text' size="small" color="primary">Filter</Button>


                            </div>

                        </div>
                    </div>
                    <br/><br/>
                    
                    {jenis_sebaran==='titik'&&<Titik filter_by_field_value={filter_by_field_value} project_info={project_info} getSebaranDots={getSebaranDots} peta_sebaran={peta_sebaran_dots} {...props}/>}
                    {jenis_sebaran==='persentase'&&<Persentase filter_by_field_value={filter_by_field_value} project_info={project_info} getSebaranPersentase={getSebaranPersentase} peta_sebaran={peta_sebaran_persentase} {...props}/>}
                    {jenis_sebaran==='nama'&&<Nama filter_by_field_value={filter_by_field_value} project_info={project_info} getSebaranDots={getSebaranDots} peta_sebaran={peta_sebaran_dots} {...props}/>}
                    {download_visible&&<ComponentToPrint
                        ref={componentRef}
                        jenis_sebaran={jenis_sebaran}
                        filter_by_field_value={filter_by_field_value}
                        filter_by_field_value_with_id={filter_by_field_value_with_id}
                        project_info={project_info}
                        getSebaranDots={getSebaranDots}
                        peta_sebaran={jenis_sebaran==='persentase'?peta_sebaran_persentase:peta_sebaran_dots}
                        getSebaranPersentase={getSebaranPersentase}
                        {...props}
                        renderShowFilter={renderShowFilter}
                    />}
                </div>
            </div>
        </div>
    )
}
