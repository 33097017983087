import {apiCall} from 'service/apiCall'
import {setLoading,modalToggle,setLoadingTable} from './general'
import Cookie from 'universal-cookie'
import {get} from 'lodash'
import * as actionTypes from 'redux/constants/proyek'
import * as clientAction from './client'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver'
import moment from 'moment'
const cookie=new Cookie
const token=cookie.get('login_cookie')

export const setProyekDetail=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_PROYEK_DETAIL,
        payload:payload
    })
}
export const resetProyek=()=>dispatch=>{
    dispatch({
        type:actionTypes.RESET_PROYEK,
        // payload:payload
    })
}
export const setTab=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_TAB,
        payload:payload
    })
}
export const resetRecapFilter=()=>dispatch=>{
    dispatch({
        type:actionTypes.RESET_RECAP_FILTER,
    })
}
export const setRecapFilter=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_RECAP_FILTER,
        payload:payload
    })
}
export const postProyek=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/project`,
            method:'POST',
            special_alert:true,
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Proyek berhasil disimpan",
                modal_component: "proyek",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const getHierarchyListForDropDown=()=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/wla/hierarchy`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))

        dispatch({
            type:actionTypes.GET_HIERARCHY_FOR_DROPDOWN,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}

export const wlaUploadFile=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/upload`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Jabatan Berhasil Di Upload",
                modal_component: "proyek",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const getProyekList=(slug)=>async dispatch=>{
    dispatch(setLoadingTable(true))

    let datasent={
        url:`/wla/list${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoadingTable(false))

        dispatch({
            type:actionTypes.GET_PROYEK,
            payload:{
                proyek_list:res.data.items,
                proyek_pagination:res.data.info
            }
        })
        return res
        
    }else{
        dispatch(setLoadingTable(false))

    }
}

export const postJabatan=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/jobtitle`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===204){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Jabatan berhasil disimpan",
                modal_component: "jabatan",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const postJabatanDetail=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/job/detail`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Jabatan berhasil diupdate",
                modal_component: "jabatan",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}


export const getWke=(slug)=>async dispatch=>{
    dispatch(setLoadingTable(true))

    let datasent={
        url:`/wla/list/wke${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoadingTable(false))

        dispatch({
            type:actionTypes.GET_WKE,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoadingTable(false))

    }
}

export const postWke=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/wke`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "WKE berhasil disimpan",
                modal_component: "proyek",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const putWke=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/wke`,
            method:'PUT',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "WKE berhasil diupdate",
                modal_component: "proyek",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const getRecapList=(slug)=>async dispatch=>{
    dispatch(setLoadingTable(true))

    let datasent={
        url:`/wla/list/recap${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoadingTable(false))
        const alignment = {
            horizontal: "left"
        }  
        let data_export=[{
            columns:[
                {title:'Unit Kerja'},
                {title:'Nama jabatan'},
                {title:'Hirarki Tugas'},
                {title:'Konsultan'},
                {title:'Batch'},
                {title:'Jml SDM'},
                {title:'Kebutuhan SDM'},
                {title:'Surplus/Deficit'},
                {title:'Status'},
            ],
            data:[]
        }]
        res.data.items.map((data)=>{
            data_export[0].data.push([
                {value:data.shortname,style:{alignment:alignment}},
                {value:data.jobTitle,style:{alignment:alignment}},
                {value:data.hierarchy===1?'TU-P':'TU-DT-P',style:{alignment:alignment}},
                {value:data.consultant,style:{alignment:alignment}},
                {value:data.batch,style:{alignment:alignment}},
                {value:data.sdm,style:{alignment:alignment}},
                {value:data.need,style:{alignment:alignment}},
                {value:data.surplus,style:{alignment:alignment}},
                {value:data.status,style:{alignment:alignment}},

            ])
        })
        dispatch({
            type:actionTypes.GET_RECAP_LIST,
            payload:{
                recap_list:res.data.items,
                recap_pagination:res.data.info,
                recap_export:data_export
            }
        })
        return res
        
    }else{
        dispatch(setLoadingTable(false))

    }
}

export const deleteRecap=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/jobtitle${slug}`,
            method:'DELETE',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===204){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Recap berhasil dihapus",
                modal_component: "recap",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const getDetailRecap=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/wla/job/detail${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))

        dispatch({
            type:actionTypes.GET_RECAP_DETAIL,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const getDetailRecapByWke=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/wla/job/detail/wke${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))

        dispatch({
            type:actionTypes.GET_RECAP_DETAIL,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const getDetailRecapByFte=(slug)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/wla/job/detail/fte${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))

        dispatch({
            type:actionTypes.GET_RECAP_DETAIL,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const postRemarks=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/job/remarks`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Remarks berhasil diupdate",
                modal_component: "remarks",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const verifyHirarki=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/job/verify${slug}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Verifikasi Berhasil",
                modal_component: "verify",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            dispatch({
                type:actionTypes.GET_RECAP_DETAIL,
                payload:res.data
            })
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const getDetailProject=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/project/detail${slug}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            let {data}=res
            dispatch(setLoading(false))
            dispatch(setProyekDetail({projectId:data.id}))
            dispatch(setProyekDetail({workUnits:data.workUnits}))
            dispatch(setProyekDetail({jobTitles:data.jobTitles}))
            dispatch(setProyekDetail({sdm:data.sdm}))
            dispatch(setProyekDetail({need:data.need}))
            dispatch(setProyekDetail({surplus:data.surplus}))
            dispatch(setProyekDetail({status:data.status}))
            dispatch(setProyekDetail({name:data.name}))
            dispatch(setProyekDetail({company:data.company}))
            dispatch(setProyekDetail({fte:data.fte}))

            dispatch(clientAction.setDetailClient({clientId:data.companyId}))
            dispatch(clientAction.setDetailClient({company:data.company}))
            dispatch(clientAction.setDetailClient({phone:data.phone}))
            dispatch(clientAction.setDetailClient({address:data.address}))
            dispatch(clientAction.setDetailClient({industry:{id:data.industryId,text:data.industry}}))
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const getWorkUnit=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/project/workunit${slug}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            dispatch({
                type:actionTypes.GET_WORK_UNIT,
                payload:res.data
            })
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const getKey=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/key${slug}`,
            method:'GET',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===200){
            dispatch(setLoading(false))
            
            return res
        }else{
            dispatch(setLoading(false))
        }
}

export const postKeyAccess=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/key`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===204){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Kode Akses berhasil di update",
                modal_component: "akses",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const updateFteProject=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/fte${slug}`,
            method:'POST',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===204){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "FTE berhasil diupdate",
                modal_component: "fte",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const deleteWKE=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/wke${slug}`,
            method:'DELETE',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===204){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "WKE berhasil dihapus",
                modal_component: "wke",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}
export const deleteProject=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla${slug}`,
            method:'DELETE',
            data:{headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===204){
            dispatch(setLoading(false))
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Project berhasil dihapus",
                modal_component: "project",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p></p> `,
                    ...res.data
                },
                modal_action:'success',
            }))
            return res
        }else{
            dispatch(setLoading(false))
        }
}



export const generateExcel=(detail,type,proyek_detail)=>async dispatch=>{
    let data=[]
    let periode=[
        {id:1,text:'Harian',value:228},
        {id:2,text:'Mingguan',value:52},
        {id:3,text:'Bulanan',value:12},
        {id:4,text:'Triwulanan',value:4},
        {id:5,text:'Semesteran',value:2},
        {id:6,text:'Tahunan',value:1},
    ]
    let findPeriodeText=(id)=>{
        let filter=periode.filter(f=>{
            return f.id===id
        })
        if(filter.length>0){
            return filter[0]
        }else{
            return {id:0,text:''}
        }
    }
    let configKat=(id)=>{
        let new_kat=[]
        type.map((t)=>{
            if(t.id===id){
                new_kat.push(1)
            }else{
                new_kat.push(0)
            }
        })
        return new_kat
    }
    if(detail.hierarchy===1){
        detail.tasks.map((d,i1)=>{
            d.processes.map((p,i2)=>{
                data.push({
                    no:i2===0?i1+1:'',
                    tugas_utama:i2===0?d.task:'',
                    detail_tugas_utama:p.process,
                    urutan_proses:'',
                    periodeText:findPeriodeText(p.periodId).text,
                    periodeValue:findPeriodeText(p.periodId).value,
                    kuantitas:p.quantity,
                    rata_rata:p.duration,
                    pegawai:p.employees,
                    kategori:configKat(p.typeId),
                    keterangan:p.remarks
                })
            })
        })
    }else{
        detail.tasks.map((d,i1)=>{
            d.children.map((c,i2)=>{
                c.processes.map((p,i3)=>{
                    data.push({
                        no:i2===0&&i3===0?i1+1:'',
                        tugas_utama:i2===0&&i3===0?d.task:'',
                        detail_tugas_utama:i3===0?c.task:'',
                        urutan_proses:p.process,
                        periodeText:findPeriodeText(p.periodId).text,
                        periodeValue:findPeriodeText((p.periodId)).value,
                        kuantitas:p.quantity,
                        rata_rata:p.duration,
                        pegawai:p.employees,
                        kategori:configKat(p.typeId),
                        keterangan:p.remarks
                    })
                })
            })
            
        })
    }
    console.log(`data`, data)
    const wb = new ExcelJS.Workbook()

    // const sheet1 = wb.addWorksheet(`# Proposal`);
    const sheet1 = wb.addWorksheet('Formulir Pengisian', {
        views: [{showGridLines: false}]
      });
      
    /*TITLE*/
    sheet1.mergeCells('A1', 'T1');
    sheet1.getCell('A1').value = `FORMULIR ANALISA BEBAN KERJA ${proyek_detail.company}`
    sheet1.getCell('A1').font = {
        name: 'Calibri',
        family: 4,
        size: 18,
        bold: true
    };

    //biodata
    sheet1.getCell('A1').alignment={horizontal:'center'}
    sheet1.getCell('A3').value=`NAMA    : ${detail.respondent.text}`
    sheet1.getCell('A4').value=`JABATAN : ${detail.jobTitle}`
    sheet1.getCell('A5').value=`Tgl Report : ${moment().format('DD MMM YYYY')}`
    sheet1.getCell('R3').value=`DIVISI  : ${detail.workUnit}`
    sheet1.getCell('R4').value=`LOKASI KERJA    : ${detail.location}`
    sheet1.getCell('R5').value=`JML PEGAWAI     : ${detail.sdm} Jumlah pegawai di posisi yang sama`
    let range=['A3','A4','R3','R4','R5']

    range.map((cell)=>{
        sheet1.getCell(cell).font={
            name: 'Calibri',
            family: 4,
            size: 12,
        }
    })
    
    let borderStyles = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
    };

    //collumn name
    sheet1.mergeCells('A7:A8');
    sheet1.getCell('A7').value='No.'
    sheet1.mergeCells('B7:B8');
    sheet1.getCell('B7').value='TUGAS UTAMA'
    sheet1.mergeCells('C7:C8');
    sheet1.getCell('C7').value='DETAIL TUGAS UTAMA'
    sheet1.mergeCells('D7:D8');
    sheet1.getCell('D7').value='URUTAN PROSES'
    sheet1.mergeCells('E7','F8');
    sheet1.getCell('E7').value='PERIODE'
    sheet1.mergeCells('G7:G8');
    sheet1.getCell('G7').value='KUANTITAS'
    sheet1.mergeCells('H7:H8');
    sheet1.getCell('H7').value='RATA-RATA WAKTU (dalam menit)'
    sheet1.mergeCells('I7:I8');
    sheet1.getCell('I7').value='Jumlah Pegawai yang terlibat'

    //config categori collumn
    let table_abj=['A','B','C','D','E','F','G','H','I','J']
    let table_cell=['A7','B7','C7','D7','E7','G7','H7','I7']
    let table_yellow=[]
    let table_yellow2=[]
    let table_blue=[]
    table_abj.map((t,i)=>{
        sheet1.getCell(`${t}7`).alignment={ vertical: 'middle', horizontal: 'center'}
    })
    let kat=type

    let kat_abj=['J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
    let kat_cell=[]
    let yellow_cell=[]
    let keterangan_cell
    kat.map((k,i)=>{
        if(i===0||i===kat.length-1){
            kat_cell.push(`${kat_abj[i]}7`)
            table_cell.push(`${kat_abj[i]}7`)
        }
        if(i===kat.length-1){
            sheet1.mergeCells(`${kat_abj[i+1]}7:${kat_abj[i+1]}8`)
            sheet1.getCell(`${kat_abj[i+1]}7`).value="KETERANGAN"
            sheet1.getCell(`${kat_abj[i+1]}7`).alignment={ vertical: 'middle', horizontal: 'center'}
            table_cell.push(`${kat_abj[i+1]}7`)
            keterangan_cell=i+1
        }
        sheet1.getCell(`${kat_abj[i]}8`).value=k.text
        sheet1.getCell(`${kat_abj[i]}8`).alignment={ textRotation: 90,vertical: 'middle', horizontal: 'center'}
        sheet1.getCell(`${kat_abj[i+kat.length+2]}8`).value=k.text
        sheet1.getCell(`${kat_abj[i+kat.length+2]}8`).alignment={ textRotation: 90,vertical: 'middle', horizontal: 'center'}
        table_yellow.push(`${kat_abj[i+kat.length+2]}8`)
        table_cell.push(`${kat_abj[i]}8`)
        table_cell.push(`${kat_abj[i+kat.length+2]}8`)
        yellow_cell.push(kat_abj[i+kat.length+2])
    })
    let kat_merge=kat_cell.join(':')
    sheet1.mergeCells(kat_merge);

    sheet1.getCell('J7').value='KATEGORI'
    
    table_cell.map((tbl)=>{
        sheet1.getCell(tbl).font={
            name: 'Calibri',
            family: 4,
            size: 12,
            bold:true
        }
        sheet1.getCell(tbl).border=borderStyles
        sheet1.getCell(tbl).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFBFBFBF'}
          };
    })
    
    let colum=[
        {key:'no',width:5},
        {key:'tugas_utama',width:30},
        {key:'detail_tugas_utama',width:45},
        {key:'urutan_proses',width:30},
        {key:'periodeText',},
        {key:'periodeValue',},
        {key:'kuantitas',width:15},
        {key:'rata_rata',width:35},
        {key:'pegawai',width:30},
    ]
    kat.map((d)=>{
        colum.push({key:d.text,width:5})
        
    })
    colum.push({key:'keterangan',width:30})
    sheet1.columns=colum
    

    //add row
    data.map((data,i)=>{
        let row=[
            data.no,
            data.tugas_utama,
            data.detail_tugas_utama,
            data.urutan_proses,
            data.periodeText,
            data.periodeValue,
            data.kuantitas,
            data.rata_rata,
            data.pegawai,
        ]
        data.kategori.map((ka)=>{
            row.push(ka)
        })
        row.push(data.keterangan)
        row.push('')
        data.kategori.map((ka)=>{
            row.push('#willkategoricount')
        })
        sheet1.addRow(row)
    })
    let new_sum=[]
    let yellow_row=[]
    kat.map((d)=>{
        new_sum.push([])
    })
    sheet1.eachRow(function(row, rowNumber) {
        if(rowNumber>=9){
            let new_r=[]
            row.eachCell((cell,cellNumb)=>{
                if(cell.value!==""||cell._column._key!==undefined){
                    cell.border = borderStyles;
                }
                if(cell.value==='#willkategoricount'){
                    new_r.push(cell)
                    table_yellow2.push(cell._address)
                }
                if(cell._column._key==='tugas_utama'||cell._column._key==='detail_tugas_utama'||cell._column._key==='urutan_proses'){
                    cell.alignment={ vertical: 'middle', horizontal: 'left',wrapText: true }
                }else{
                    cell.alignment={ vertical: 'middle', horizontal: 'center',wrapText: true }
                }
                cell.font={
                    name: 'Calibri',
                    family: 4,
                    size: 9,
                }
            })

            //count yellow table
            new_r.map((r,i)=>{
                new_sum[i].push(r._address)
                let renderK=`${kat_abj[i]}${rowNumber}`

                r.value={formula:`F${rowNumber}*G${rowNumber}*H${rowNumber}*I${rowNumber}*${renderK}`}
                // if(sheet1.getCell(`${kat_abj[i]}${rowNumber}`).value!==''){
                //     r.value={formula:`F${rowNumber}*G${rowNumber}*H${rowNumber}*I${rowNumber}*${renderK}`}
                // }else{
                //     r.value={formula:`F${rowNumber}*G${rowNumber}*H${rowNumber}*I${rowNumber}*${renderK}`}
                // }
               
            })
            yellow_row.push(rowNumber)
        }
      });
      
      if(data.length>0){
        let yellow_sum_row=yellow_row[yellow_row.length-1]+1
        let yellow_percent_row=yellow_row[yellow_row.length-1]+2
        let new_total_beban_cell=[]
        let table_result=[]
        new_sum.map((ns,i)=>{
          new_total_beban_cell.push(`${yellow_cell[i]}${yellow_sum_row}`)
          sheet1.getCell(`${yellow_cell[i]}${yellow_sum_row}`).value={formula:`SUM(${ns[0]}:${ns[ns.length-1]})`}
          sheet1.getCell(`${yellow_cell[i]}${yellow_sum_row}`).alignment={ vertical: 'middle', horizontal: 'center'}
          sheet1.getCell(`${yellow_cell[i]}${yellow_percent_row}`).value={formula:`${yellow_cell[i]}${yellow_sum_row}/(SUM(${yellow_cell[0]}${yellow_sum_row}:${yellow_cell[yellow_cell.length-1]}${yellow_sum_row}))`}
          sheet1.getCell(`${yellow_cell[i]}${yellow_percent_row}`).alignment={ vertical: 'middle', horizontal: 'center'}
          sheet1.getCell(`${yellow_cell[i]}${yellow_percent_row}`).numFmt = '0%';
          table_blue.push(`${yellow_cell[i]}${yellow_sum_row}`)
          table_blue.push(`${yellow_cell[i]}${yellow_percent_row}`)
        })

        //keterangan
        sheet1.mergeCells(`A${yellow_percent_row+2}:G${yellow_percent_row+10}`)
        sheet1.getCell(`A${yellow_percent_row+2}`).value=detail.remarks
        sheet1.getCell(`A${yellow_percent_row+2}`).border={
            top: {style:'medium', color: {argb:'FFFF0000'}},
            left: {style:'medium', color: {argb:'FFFF0000'}},
            bottom: {style:'medium', color: {argb:'FFFF0000'}},
            right: {style:'medium', color: {argb:'FFFF0000'}}
        };
        sheet1.getCell(`A${yellow_percent_row+2}`).alignment={ vertical: 'top', horizontal: 'left',wrapText: true };

        sheet1.mergeCells(`${kat_abj[keterangan_cell]}${yellow_percent_row+4}:${kat_abj[keterangan_cell+1]}${yellow_percent_row+4}`)
        sheet1.getCell(`${kat_abj[keterangan_cell]}${yellow_percent_row+4}`).value='TOTAL BEBAN KERJA'
        sheet1.getCell(`${kat_abj[keterangan_cell+2]}${yellow_percent_row+4}`).value={formula:`SUM(${new_total_beban_cell.join(':')})`}
        table_result.push(`${kat_abj[keterangan_cell]}${yellow_percent_row+4}`)
        table_result.push(`${kat_abj[keterangan_cell+2]}${yellow_percent_row+4}`)
        
        // let new_wke=0

        sheet1.mergeCells(`${kat_abj[keterangan_cell]}${yellow_percent_row+5}:${kat_abj[keterangan_cell+1]}${yellow_percent_row+5}`)
        sheet1.getCell(`${kat_abj[keterangan_cell]}${yellow_percent_row+5}`).value='WKE per Tahun'
        sheet1.getCell(`${kat_abj[keterangan_cell+2]}${yellow_percent_row+5}`).value=detail.wke.totalWKE
        table_result.push(`${kat_abj[keterangan_cell]}${yellow_percent_row+5}`)
        table_result.push(`${kat_abj[keterangan_cell+2]}${yellow_percent_row+5}`)
  
        sheet1.mergeCells(`${kat_abj[keterangan_cell]}${yellow_percent_row+6}:${kat_abj[keterangan_cell+1]}${yellow_percent_row+6}`)
        sheet1.getCell(`${kat_abj[keterangan_cell]}${yellow_percent_row+6}`).value='FTE'
        sheet1.getCell(`${kat_abj[keterangan_cell+2]}${yellow_percent_row+6}`).value={formula:`${kat_abj[keterangan_cell+2]}${yellow_percent_row+4}/${kat_abj[keterangan_cell+2]}${yellow_percent_row+5}`}
        sheet1.getCell(`${kat_abj[keterangan_cell+2]}${yellow_percent_row+6}`).numFmt='0'
        table_result.push(`${kat_abj[keterangan_cell]}${yellow_percent_row+6}`)
        table_result.push(`${kat_abj[keterangan_cell+2]}${yellow_percent_row+6}`)
  
        sheet1.mergeCells(`${kat_abj[keterangan_cell]}${yellow_percent_row+7}:${kat_abj[keterangan_cell+1]}${yellow_percent_row+7}`)
        sheet1.getCell(`${kat_abj[keterangan_cell]}${yellow_percent_row+7}`).value='Jumlah Berdasarkan ABK'
        sheet1.getCell(`${kat_abj[keterangan_cell+2]}${yellow_percent_row+7}`).value={formula:`ROUNDUP(${kat_abj[keterangan_cell+2]}${yellow_percent_row+6}/1.25,0)`}
        table_result.push(`${kat_abj[keterangan_cell]}${yellow_percent_row+7}`)
        table_result.push(`${kat_abj[keterangan_cell+2]}${yellow_percent_row+7}`)

        sheet1.mergeCells(`${kat_abj[keterangan_cell]}${yellow_percent_row+8}:${kat_abj[keterangan_cell+1]}${yellow_percent_row+8}`)
        sheet1.getCell(`${kat_abj[keterangan_cell]}${yellow_percent_row+8}`).value='Jumlah Pegawai saat ini'
        sheet1.getCell(`${kat_abj[keterangan_cell+2]}${yellow_percent_row+8}`).value=detail.sdm
        table_result.push(`${kat_abj[keterangan_cell]}${yellow_percent_row+8}`)
        table_result.push(`${kat_abj[keterangan_cell+2]}${yellow_percent_row+8}`)

        sheet1.mergeCells(`${kat_abj[keterangan_cell]}${yellow_percent_row+9}:${kat_abj[keterangan_cell+1]}${yellow_percent_row+9}`)
        sheet1.getCell(`${kat_abj[keterangan_cell]}${yellow_percent_row+9}`).value='Kurang / Lebih'
        sheet1.getCell(`${kat_abj[keterangan_cell+2]}${yellow_percent_row+9}`).value={formula:`${kat_abj[keterangan_cell+2]}${yellow_percent_row+8}-${kat_abj[keterangan_cell+2]}${yellow_percent_row+7}`}
        table_blue.push(`${kat_abj[keterangan_cell]}${yellow_percent_row+9}`)
        table_blue.push(`${kat_abj[keterangan_cell+2]}${yellow_percent_row+9}`)
        
        
        table_result.map((tbl)=>{
            sheet1.getCell(tbl).border=borderStyles
        })
      }
      

     
      table_yellow.map((tbl)=>{
        sheet1.getCell(tbl).font={
            name: 'Calibri',
            family: 4,
            size: 12,
            bold:true
        }
        sheet1.getCell(tbl).border=borderStyles
        sheet1.getCell(tbl).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFF00'}
          };
    })
    table_yellow2.map((tbl)=>{
        sheet1.getCell(tbl).font={
            name: 'Calibri',
            family: 4,
            size: 9,
        }
        sheet1.getCell(tbl).border=borderStyles
        sheet1.getCell(tbl).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFFFFF00'}
          };
    })
    table_blue.map((tbl)=>{
        sheet1.getCell(tbl).font={
            name: 'Calibri',
            family: 4,
            size: 9,
        }
        sheet1.getCell(tbl).border=borderStyles
        sheet1.getCell(tbl).fill = {
            type: 'pattern',
            pattern:'solid',
            fgColor:{argb:'FFBDD7EE'}
          };
    })

    
    const buf = await wb.xlsx.writeBuffer()
    return buf
}

export const exportRbk=(detail,type,proyek_detail)=>async dispatch=>{
    let buf=await dispatch(generateExcel(detail,type,proyek_detail))
    // console.log(`buf`, new Blob([buf]))
    saveAs(new Blob([buf]), `${proyek_detail.company}-${detail.jobTitle} (${detail.shortname}).xlsx`)
}

export const exportAll=(detail,type,proyek_detail)=>async dispatch=>{
    let buf=await dispatch(generateExcel(detail,type,proyek_detail))
    return buf
}



export const getRecapColumns=(projectId)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/wla/list/columns/${projectId}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))

        dispatch({
            type:actionTypes.GET_RECAP_COLUMN,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const getDetailRecapCollumns=(jobTitleId)=>async dispatch=>{
    dispatch(setLoading(true))

    let datasent={
        url:`/wla/columns/${jobTitleId}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))

        dispatch({
            type:actionTypes.GET_RECAP_DETAIL_COLUMN,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}