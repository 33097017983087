import React,{useState} from 'react'
import List from './List'
import Detail from './Detail'
import Detail_rbk from './detail_rbk'
import './style.css' 
import Layout from 'components/Layouts'
import DetailClient from './detail_client'
import * as proyekAction from 'redux/actions/proyek'
import {useDispatch,useSelector} from 'react-redux'
export default function Index(props) {
    // const [tab, settab] = useState('list')
    const dispatch = useDispatch()
    const proyek = useSelector(state => state.proyek)
    const {tab}=proyek
    const tabToggle=(key)=>{dispatch(proyekAction.setTab(key))}
    return (
        <div>
            <Layout>
                    {tab==='list'&&<List {...props}  tab={tab} tabToggle={tabToggle}/>}
                    {tab==='add'&&<Detail {...props}  tab={tab} tabToggle={tabToggle}/>}
                    {tab==='detail'&&<Detail {...props}  tab={tab} tabToggle={tabToggle}/>}
                    {tab==='edit'&&<Detail {...props}  tab={tab} tabToggle={tabToggle}/>}
                    {tab==='detail_rbk'&&<Detail_rbk {...props}  tab={tab} tabToggle={tabToggle}/>}
                    {tab==='edit_rbk'&&<Detail_rbk {...props}  tab={tab} tabToggle={tabToggle}/>}
                    {tab==='detail_client'&&<DetailClient {...props}  tab={tab} tabToggle={tabToggle}/>}
            </Layout>
        </div>
    )
}
