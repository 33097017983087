import React, { useState,useEffect } from 'react'
import TextField from 'components/TextField'
import { TwitterPicker } from 'react-color';
import { InputAdornment } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { useDispatch,useSelector } from 'react-redux';
import * as actionTalenta from 'redux/actions/talenta'
import * as actionGeneral from 'redux/actions/general'

const Picker=(props)=>{
    let {chooseBgColor,color}=props
    const [open, setopen] = useState(false)
    const popover = {
        position: 'absolute',
        zIndex: '2',
      }
      const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }
    const onChangeComplete=(color)=>{
        setopen(false)
        chooseBgColor(color)
    }
    return(
        <div>
            <div onClick={()=>setopen(!open)} style={{cursor:'pointer',width:40,height:40,backgroundColor:color,borderRadius:10}}></div>
            { open ? <div style={ popover }>
            <div style={ cover } onClick={ ()=>setopen(false) }/>
            <TwitterPicker 
                color={ color }
                onChangeComplete={ onChangeComplete }
            />
            </div> : null }
        </div>
    )
}

const BoxTalenta=(props)=>{
    let {box,chooseBox,project_info,active_box}=props
    return(
        <div className='box-talenta-wrapper'>
            <div className='box-talenta-container'>
                <p className='kinerja-text' >{project_info.yAxis}</p>
                <p className='potensial-text' >{project_info.xAxis}</p>
                <div className='box-talenta-grid'>
                    <div onClick={()=>chooseBox(3)} style={{border:`1.5px dashed ${box[3].bgColor}`,backgroundColor:(box[3].isChangeColor||active_box===3)&&box[3].bgColor}}>
                        {box[3].maxY!==""&&<p style={{margin:0,fontSize:11,fontWeight:600,position:'absolute',top:0,right:115}}><i>{`<${box[3].maxY===0?'~':box[3].maxY}`}</i></p>}
                        {box[3].minY!==""&&<p style={{margin:0,fontSize:11,fontWeight:600,position:'absolute',bottom:0,right:115}}><i>≥{box[3].minY}</i></p>}
                        <p style={{margin:0,fontSize:12,fontWeight:'bold'}}><i>{box[3].label}</i></p>
                    </div>
                    <div onClick={()=>chooseBox(6)} style={{border:`1.5px dashed ${box[6].bgColor}`,backgroundColor:(box[6].isChangeColor||active_box===6)&&box[6].bgColor}}>
                        <p style={{margin:0,fontSize:12,fontWeight:'bold'}}><i>{box[6].label}</i></p>

                    </div>
                    <div onClick={()=>chooseBox(8)} style={{border:`1.5px dashed ${box[8].bgColor}`,backgroundColor:(box[8].isChangeColor||active_box===8)&&box[8].bgColor}}>
                        <p style={{margin:0,fontSize:12,fontWeight:'bold'}}><i>{box[8].label}</i></p>
                    </div>
                    <div onClick={()=>chooseBox(1)} style={{border:`1.5px dashed ${box[1].bgColor}`,backgroundColor:(box[1].isChangeColor||active_box===1)&&box[1].bgColor}}>
                        {box[1].maxY!==""&&<p style={{margin:0,fontSize:11,fontWeight:600,position:'absolute',top:0,right:115}}><i>{`<${box[1].maxY}`}</i></p>}
                        {box[1].minY!==""&&<p style={{margin:0,fontSize:11,fontWeight:600,position:'absolute',bottom:0,right:115}}><i>≥{box[1].minY}</i></p>}
                        <p style={{margin:0,fontSize:12,fontWeight:'bold'}}><i>{box[1].label}</i></p>
                    </div>
                    <div onClick={()=>chooseBox(4)} style={{border:`1.5px dashed ${box[4].bgColor}`,backgroundColor:(box[4].isChangeColor||active_box===4)&&box[4].bgColor}}>
                        <p style={{margin:0,fontSize:12,fontWeight:'bold'}}><i>{box[4].label}</i></p>
                    </div>
                    <div onClick={()=>chooseBox(7)} style={{border:`1.5px dashed ${box[7].bgColor}`,backgroundColor:(box[7].isChangeColor||active_box===7)&&box[7].bgColor}}>
                        <p style={{margin:0,fontSize:12,fontWeight:'bold'}}><i>{box[7].label}</i></p>
                    </div>
                    <div onClick={()=>chooseBox(0)} style={{border:`1.5px dashed ${box[0].bgColor}`,backgroundColor:(box[0].isChangeColor||active_box===0)&&box[0].bgColor}}>
                        
                        {box[0].minY!==""&&<p style={{margin:0,fontSize:11,fontWeight:600,position:'absolute',bottom:0,right:115}}><i>{box[0].minY}</i></p>}
                        {box[0].maxY!==""&&<p style={{margin:0,fontSize:11,fontWeight:600,position:'absolute',right:115,top:0}}><i>{`<${box[0].maxY}`}</i></p>}
                        {box[0].minX!==""&&<p style={{margin:0,fontSize:11,fontWeight:600,position:'absolute',bottom:-18,left:0}}><i>{box[0].minX}</i></p>}
                        {box[0].maxX!==""&&<p style={{margin:0,fontSize:11,fontWeight:600,position:'absolute',right:-3,bottom:-18}}><i>{`<${box[0].maxX}`}</i></p>}

                        <p style={{margin:0,fontSize:12,fontWeight:'bold'}}><i>{box[0].label}</i></p>
                    </div>
                    <div onClick={()=>chooseBox(2)} style={{border:`1.5px dashed ${box[2].bgColor}`,backgroundColor:(box[2].isChangeColor||active_box===2)&&box[2].bgColor}}>
                        {box[2].minX!==""&&<p style={{margin:0,fontSize:11,fontWeight:600,position:'absolute',bottom:-18,left:0}}><i>≥{box[2].minX}</i></p>}
                        {box[2].maxX!==""&&<p style={{margin:0,fontSize:11,fontWeight:600,position:'absolute',right:-3,bottom:-18}}><i>{`<${box[2].maxX}`}</i></p>}

                        <p style={{margin:0,fontSize:12,fontWeight:'bold'}}><i>{box[2].label}</i></p>
                    </div>
                    <div onClick={()=>chooseBox(5)} style={{border:`1.5px dashed ${box[5].bgColor}`,backgroundColor:(box[5].isChangeColor||active_box===5)&&box[5].bgColor}}>
                        {box[5].maxX!==""&&<p style={{margin:0,fontSize:11,fontWeight:600,position:'absolute',right:-3,bottom:-18}}><i>{`<${box[5].maxX===0?'~':box[5].maxX}`}</i></p>}
                        {box[5].minX!==""&&<p style={{margin:0,fontSize:11,fontWeight:600,position:'absolute',bottom:-18,left:0}}><i>≥{box[5].minX}</i></p>}

                        <p style={{margin:0,fontSize:12,fontWeight:'bold'}}><i>{box[5].label}</i></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default function Talent_map(props) {
    const dispatch = useDispatch()
    const talenta = useSelector(state => state.talenta)
    let {project_info,list_map_talenta}=talenta
    let {profile,modal_data}=props
    const [active_box, setactive_box] = useState(0)
    const [error, seterror] = useState('')
    const [box, setbox] = useState([
        {   
            id:0,
            name:'Kotak 1',
            label:'Kotak 1',
            x:0,
            y:0,
            minX:'',
            maxX:'',
            minY:'',
            maxY:'',
            bgColor:'#fbda4d',
            isChangeColor:false,
            boxId:1
        },
        {
            id:0,
            name:'Kotak 2',
            label:'Kotak 2',
            x:0,
            y:1,
            minX:'',
            maxX:'',
            minY:'',
            maxY:'',
            bgColor:'#fbda4d',
            isChangeColor:false,
            boxId:2


        },
        {
            id:0,
            name:'Kotak 3',
            label:'Kotak 3',
            x:1,
            y:0,
            minX:'',
            maxX:'',
            minY:'',
            maxY:'',
            bgColor:'#fbda4d',
            isChangeColor:false,
            boxId:3


        },
        {
            id:0,
            name:'Kotak 4',
            label:'Kotak 4',
            x:0,
            y:2,
            minX:'',
            maxX:'',
            minY:'',
            maxY:'',
            bgColor:'#fbda4d',
            isChangeColor:false,
            boxId:4


        },
        {
            id:0,
            name:'Kotak 5',
            label:'Kotak 5',
            x:1,
            y:1,
            minX:'',
            maxX:'',
            minY:'',
            maxY:'',
            bgColor:'#fbda4d',
            isChangeColor:false,
            boxId:5


        },
        {
            id:0,
            name:'Kotak 6',
            label:'Kotak 6',
            x:2,
            y:0,
            minX:'',
            maxX:'',
            minY:'',
            maxY:'',
            bgColor:'#fbda4d',
            isChangeColor:false,
            boxId:6

        },
        {
            id:0,
            name:'Kotak 7',
            label:'Kotak 7',
            x:1,
            y:2,
            minX:'',
            maxX:'',
            minY:'',
            maxY:'',
            bgColor:'#4d97fc',
            isChangeColor:false,
            boxId:7

        },
        {
            id:0,
            name:'Kotak 8',
            label:'Kotak 8',
            x:2,
            y:1,
            minX:'',
            maxX:'',
            minY:'',
            maxY:'',
            bgColor:'#4d97fc',
            isChangeColor:false,
            boxId:8

        },
        {
            id:0,
            name:'Kotak 9',
            label:'Kotak 9',
            x:2,
            y:2,
            minX:'',
            maxX:'',
            minY:'',
            maxY:'',
            bgColor:'#4d97fc',
            isChangeColor:false,
            boxId:9

        },
    ])
    useEffect(() => {
        if(list_map_talenta.length>0){
            let new_box=[]
            box[0].id=list_map_talenta[0].id
            box[0].label=list_map_talenta[0].label
            box[0].x=list_map_talenta[0].x
            box[0].y=list_map_talenta[0].y
            box[0].minX=list_map_talenta[0].minX
            box[0].maxX=list_map_talenta[0].maxX
            box[0].minY=list_map_talenta[0].minY
            box[0].maxY=list_map_talenta[0].maxY
            box[0].bgColor=list_map_talenta[0].bgColor
            box[0].isChangeColor=true

            box[1].id=list_map_talenta[1].id
            box[1].label=list_map_talenta[1].label
            box[1].x=list_map_talenta[1].x
            box[1].y=list_map_talenta[1].y
            box[1].minX=list_map_talenta[1].minX
            box[1].maxX=list_map_talenta[1].maxX
            box[1].minY=list_map_talenta[1].minY
            box[1].maxY=list_map_talenta[1].maxY
            box[1].bgColor=list_map_talenta[1].bgColor
            box[1].isChangeColor=true

            box[2].id=list_map_talenta[3].id
            box[2].label=list_map_talenta[3].label
            box[2].x=list_map_talenta[3].x
            box[2].y=list_map_talenta[3].y
            box[2].minX=list_map_talenta[3].minX
            box[2].maxX=list_map_talenta[3].maxX
            box[2].minY=list_map_talenta[3].minY
            box[2].maxY=list_map_talenta[3].maxY
            box[2].bgColor=list_map_talenta[3].bgColor
            box[2].isChangeColor=true

            box[3].id=list_map_talenta[2].id
            box[3].label=list_map_talenta[2].label
            box[3].x=list_map_talenta[2].x
            box[3].y=list_map_talenta[2].y
            box[3].minX=list_map_talenta[2].minX
            box[3].maxX=list_map_talenta[2].maxX
            box[3].minY=list_map_talenta[2].minY
            box[3].maxY=list_map_talenta[2].maxY
            box[3].bgColor=list_map_talenta[2].bgColor
            box[3].isChangeColor=true

            box[4].id=list_map_talenta[4].id
            box[4].label=list_map_talenta[4].label
            box[4].x=list_map_talenta[4].x
            box[4].y=list_map_talenta[4].y
            box[4].minX=list_map_talenta[4].minX
            box[4].maxX=list_map_talenta[4].maxX
            box[4].minY=list_map_talenta[4].minY
            box[4].maxY=list_map_talenta[4].maxY
            box[4].bgColor=list_map_talenta[4].bgColor
            box[4].isChangeColor=true

            box[5].id=list_map_talenta[6].id
            box[5].label=list_map_talenta[6].label
            box[5].x=list_map_talenta[6].x
            box[5].y=list_map_talenta[6].y
            box[5].minX=list_map_talenta[6].minX
            box[5].maxX=list_map_talenta[6].maxX
            box[5].minY=list_map_talenta[6].minY
            box[5].maxY=list_map_talenta[6].maxY
            box[5].bgColor=list_map_talenta[6].bgColor
            box[5].isChangeColor=true

            box[6].id=list_map_talenta[5].id
            box[6].label=list_map_talenta[5].label
            box[6].x=list_map_talenta[5].x
            box[6].y=list_map_talenta[5].y
            box[6].minX=list_map_talenta[5].minX
            box[6].maxX=list_map_talenta[5].maxX
            box[6].minY=list_map_talenta[5].minY
            box[6].maxY=list_map_talenta[5].maxY
            box[6].bgColor=list_map_talenta[5].bgColor
            box[6].isChangeColor=true

            box[7].id=list_map_talenta[7].id
            box[7].label=list_map_talenta[7].label
            box[7].x=list_map_talenta[7].x
            box[7].y=list_map_talenta[7].y
            box[7].minX=list_map_talenta[7].minX
            box[7].maxX=list_map_talenta[7].maxX
            box[7].minY=list_map_talenta[7].minY
            box[7].maxY=list_map_talenta[7].maxY
            box[7].bgColor=list_map_talenta[7].bgColor
            box[7].isChangeColor=true

            box[8].id=list_map_talenta[8].id
            box[8].label=list_map_talenta[8].label
            box[8].x=list_map_talenta[8].x
            box[8].y=list_map_talenta[8].y
            box[8].minX=list_map_talenta[8].minX
            box[8].maxX=list_map_talenta[8].maxX
            box[8].minY=list_map_talenta[8].minY
            box[8].maxY=list_map_talenta[8].maxY
            box[8].bgColor=list_map_talenta[8].bgColor
            box[8].isChangeColor=true
            setbox([...box])
        }
    }, [])
    const chooseBox=(i)=>{
        setactive_box(i)
    }
    const chooseBgColor=(color)=>{
        box[active_box].bgColor=color.hex
        box[active_box].isChangeColor=true
        setbox([...box])

    }
    const onChange=(e)=>{
        let {value,name}=e.target
        box[active_box][name]=value
        setbox([...box])
    }
    
    const onChange2=(value,key)=>{
        let {boxId}=box[active_box]
        if(boxId===1){
            if(key==='minX'){
                box[1].minX=value
                box[3].minX=value
            }
            if(key==='maxX'){
                box[1].maxX=value
                box[3].maxX=value

                box[2].minX=value
                box[4].minX=value
                box[6].minX=value
            }
            if(key==='minY'){
                box[2].minY=value
                box[5].minY=value
            }
            if(key==='maxY'){
                box[2].maxY=value
                box[5].maxY=value

                box[1].minY=value
                box[4].minY=value
                box[7].minY=value
            }
        }
        if(boxId===2){
            if(key==='minX'){
                box[0].minX=value
                box[3].minX=value
            }
            if(key==='maxX'){
                box[0].maxX=value
                box[3].maxX=value

                box[6].minX=value
                box[4].minX=value
                box[2].minX=value
            }

            if(key==='minY'){
                box[0].maxY=value
                box[2].maxY=value
                box[5].maxY=value

                box[4].minY=value
                box[7].minY=value
            }
            if(key==='maxY'){
                box[4].maxY=value
                box[7].maxY=value
                box[3].minY=value
                box[6].minY=value
                box[8].minY=value
            }
        }
        if(boxId===3){
            if(key==='minX'){
                box[4].minX=value
                box[6].minX=value
                box[0].maxX=value
                box[1].maxX=value
                box[3].maxX=value
            }
            if(key==='maxX'){
                box[4].maxX=value
                box[6].maxX=value
                box[5].minX=value
                box[7].minX=value
                box[8].minX=value
            }
            if(key==='minY'){
                box[0].minY=value
                box[5].minY=value
                // box[1].minY=value
            }
            if(key==='maxY'){
                box[0].maxY=value
                box[5].maxY=value
                box[1].minY=value
                box[4].minY=value
                box[7].minY=value
            }
        }
        if(boxId===4){
            if(key==='minX'){
                box[0].minX=value
                box[1].minX=value
            }
            if(key==='maxX'){
                box[0].maxX=value
                box[1].maxX=value
                box[6].minX=value
                box[4].minX=value
                box[2].minX=value
            }
            if(key==='minY'){
                box[6].minY=value
                box[8].minY=value
                box[1].maxY=value
                box[4].maxY=value
                box[7].maxY=value
            }
            if(key==='maxY'){
                box[6].maxY=value
                box[8].maxY=value
            }
        }
        if(boxId===5){
            if(key==='minX'){
                box[6].minX=value
                box[2].minX=value
                box[0].maxX=value
                box[1].maxX=value
                box[3].maxX=value
            }
            if(key==='maxX'){
                box[6].maxX=value
                box[2].maxX=value
                box[8].minX=value
                box[7].minX=value
                box[5].minX=value
            }
            if(key==='minY'){
                box[1].minY=value
                box[7].minY=value
                box[0].maxY=value
                box[2].maxY=value
                box[5].maxY=value
            }
            if(key==='maxY'){
                box[1].maxY=value
                box[7].maxY=value
                box[3].minY=value
                box[6].minY=value
                box[8].minY=value
            }
        }
        if(boxId===6){
            if(key==='minX'){
                box[8].minX=value
                box[7].minX=value
                box[2].maxX=value
                box[4].maxX=value
                box[6].maxX=value
            }
            if(key==='maxX'){
                box[8].maxX=value
                box[7].maxX=value
            }
            if(key==='minY'){
                box[0].minY=value
                box[2].minY=value
            }
            if(key==='maxY'){
                box[0].maxY=value
                box[2].maxY=value
                box[1].minY=value
                box[4].minY=value
                box[7].minY=value
            }
        }
        if(boxId===7){
            if(key==='minX'){
                box[2].minX=value
                box[4].minX=value
                box[3].maxX=value
                box[1].maxX=value
                box[0].maxX=value
            }
            if(key==='maxX'){
                box[4].maxX=value
                box[2].maxX=value
                box[8].minX=value
                box[7].minX=value
                box[5].minX=value
            }
            if(key==='minY'){
                box[3].minY=value
                box[8].minY=value
                box[1].maxY=value
                box[4].maxY=value
                box[7].maxY=value
            }
            if(key==='maxY'){
                box[3].maxY=value
                box[8].maxY=value
            }
        }
        if(boxId===8){
            if(key==='minX'){
                box[5].minX=value
                box[8].minX=value
                box[2].maxX=value
                box[4].maxX=value
                box[6].maxX=value
            }
            if(key==='maxX'){
                box[8].maxX=value
                box[5].maxX=value
            }
            if(key==='minY'){
                box[1].minY=value
                box[4].minY=value
                box[0].maxY=value
                box[2].maxY=value
                box[5].maxY=value
            }
            if(key==='maxY'){
                box[1].maxY=value
                box[4].maxY=value
                box[3].minY=value
                box[6].minY=value
                box[8].minY=value
            }
        }
        if(boxId===9){
            if(key==='minX'){
                box[7].minX=value
                box[5].minX=value
                box[6].maxX=value
                box[4].maxX=value
                box[2].maxX=value
            }
            if(key==='maxX'){
                box[7].maxX=value
                box[5].maxX=value
            }
            if(key==='minY'){
                box[3].minY=value
                box[6].minY=value
                box[1].maxY=value
                box[4].maxY=value
                box[7].maxY=value
            }
            if(key==='maxY'){
                box[3].maxY=value
                box[6].maxY=value
            }
        }
        
        box[active_box][key]=value
        setbox([...box])
        if(key==='minX'||key==='maxX'){
            if(box[active_box].maxX!==""&&!box_outer.includes(active_box)&&parseInt(box[active_box].minX)>parseInt(box[active_box].maxX)){
                seterror('Nilai Batas Bawah tidak boleh lebih Besar dari Batas Atas ')
            }else{
                seterror('')
            }
        }
        if(key==='minY'||key==='maxY'){
            if(box[active_box].maxY!==""&&!box_outer.includes(active_box)&&parseInt(box[active_box].minY)>parseInt(box[active_box].maxY)){
                seterror('Nilai Batas Bawah tidak boleh lebih Besar dari Batas Atas ')
            }else{
                seterror('')
            }
        }
    }
    let box_outer=[3,6,8,7,5]

    const onSave=async ()=>{
        let data=[]
        box.map((d)=>{

            data.push({
                id:d.id,
                projectId:project_info.id,
                x:d.x,
                y:d.y,
                label:d.label,
                minX:parseInt(d.minX),
                maxX:d.maxX==='~'?0:parseInt(d.maxX),
                minY:parseInt(d.minY),
                maxY:d.maxY==='~'?0:parseInt(d.maxY),
                bgColor:d.bgColor

            })
        })
        // console.log(`box`, box)

        let payload={
            items:data,
            userId:profile.id
        }
        let res=await dispatch(actionTalenta.postMapTalenta(payload,profile.id))
        if(res){
            modal_data.afterPost()
            return res

        }
    }
    const onSaveAsTemplate=async ()=>{
        let save=await onSave()
        if(save){
            dispatch(actionGeneral.modalToggle({
                modal_open: true,
                modal_title: `Simpan Template`,
                modal_component: "save_template",
                modal_size:450,
                modal_type:'save_template',
                modal_data:{},
                modal_action:'save_template'
            }))
        }
        
    }
    return (
        <div>
            <div className='div-flex div-space-between'>
                <div style={{width:'43%'}}>
                    <p className='semi-bold'>{box[active_box].name}</p>
                    <TextField
                        label={box[active_box].name}
                        onChange={onChange}
                        value={box[active_box].label}
                        color='primary'
                        variant='outlined'
                        size='small'
                        name='label'
                        style={{marginBottom:20}}
                    />
                    <div className='div-flex'>
                        <div style={{width:'80%'}}>
                            <TextField
                                label={`Warna ${box[active_box].name}`}
                                // onChange={(e)=>setstate({...state,nama:e.target.value})}
                                value={box[active_box].bgColor}
                                color='primary'
                                variant='outlined'
                                size='small'
                                name='name'
                                disabled
                                style={{marginBottom:20}}
                            />
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <Picker 
                            color={box[active_box].bgColor}
                            chooseBgColor={chooseBgColor}
                        
                        />
                    </div>
                    <TextField
                        label={`Nilai batas bawah ${project_info.xAxis}`}
                        onChange={(e)=>onChange2(e.target.value,'minX')}
                        value={box[active_box].minX}
                        placeholder={`nilai batas bawah ${project_info.xAxis}`}
                        color='primary'
                        variant='outlined'
                        size='small'
                        name='name'
                        error={box[active_box].maxX!==""&&!box_outer.includes(active_box)&&parseInt(box[active_box].minX)>parseInt(box[active_box].maxX)}
                        style={{marginBottom:20}}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{"≥"}</InputAdornment>,
                        }}
                    />
                    <TextField
                        label={`Nilai batas atas ${project_info.xAxis} `}
                        onChange={(e)=>onChange2(e.target.value,'maxX')}
                        value={box[active_box].maxX===0?'~':box[active_box].maxX}
                        placeholder={`nilai batas atas ${project_info.xAxis}`}
                        error={box[active_box].maxX!==""&&!box_outer.includes(active_box)&&parseInt(box[active_box].maxX)<parseInt(box[active_box].minX)}

                        color='primary'
                        variant='outlined'
                        size='small'
                        name='name'
                        style={{marginBottom:20}}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{"<"}</InputAdornment>,
                        }}
                    />
                    <TextField
                        label={`Nilai batas bawah ${project_info.yAxis}`}
                        onChange={(e)=>onChange2(e.target.value,'minY')}

                        value={box[active_box].minY}
                        placeholder={`nilai batas bawah ${project_info.yAxis}`}
                        error={box[active_box].maxY!==""&&!box_outer.includes(active_box)&&parseInt(box[active_box].minY)>parseInt(box[active_box].maxY)}
                        color='primary'
                        variant='outlined'
                        size='small'
                        name='name'
                        style={{marginBottom:20}}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{"≥"}</InputAdornment>,
                        }}
                    />
                    <TextField
                        label={`Nilai batas atas ${project_info.yAxis}`}
                        onChange={(e)=>onChange2(e.target.value,'maxY',box[active_box].boxId)}

                        value={box[active_box].maxY===0?'~':box[active_box].maxY}
                        error={box[active_box].maxY!==""&&!box_outer.includes(active_box)&&parseInt(box[active_box].maxY)<parseInt(box[active_box].minY)}
                        
                        placeholder={`nilai batas atas ${project_info.yAxis}`}

                        color='primary'
                        variant='outlined'
                        size='small'
                        name='name'
                        style={{marginBottom:20}}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">{"<"}</InputAdornment>,
                        }}
                    />
                </div>
                <div style={{width:'53%'}}>
                    <p className='semi-bold'>Kotak Manajemen Talenta</p>
                    <BoxTalenta
                        box={box}
                        chooseBox={chooseBox}
                        project_info={project_info}
                        active_box={active_box}
                    />
                    {error!==""&&<div style={{padding:'0 0 30px 30px'}}><p style={{color:'red',margin:'0 0 10px 0',fontSize:14,fontWeight:600}}>{error}</p></div>}
                </div>
            </div>
            <div className='div-flex div-space-between div-align-center'>
                <div style={{width:400}}>
                    <p style={{margin:0,fontSize:12,color:'#252525',fontWeight:600}}>Lengkapi data dari masing masing <i>talent pool</i> yang anda perlukan. kotak dari <i>talent pool</i> akan berwarna jika data dari <i>talent pool</i> lengkap dan berhasil tersimpan di sistem</p>
                </div>
                <Button onClick={onSaveAsTemplate} disabled={error!==""} className='btn-remove-capital' color="primary" variant="text" color="primary" size="small">Simpan Sebagai Template</Button>
                <Button onClick={onSave} disabled={error!==""} className='btn-rounded btn-remove-capital' color="primary" variant="contained" color="primary" size="small">Simpan</Button>
                
            </div>
            <br/>
        </div>
    )
}
