import React,{useState} from 'react'
import List from './List'
import Detail from './Detail'
import DetailProject from './detail_project'
import DetailRbk from './detail_rbk'
import './style.css' 
import Layout from 'components/Layouts'
// import { MuiThemeProvider, createTheme,withStyles, makeStyles} from '@material-ui/core/styles'
// const themeButton = createTheme({ 
//     palette: { 
//         primary: {
//             main:'#00a1ed',
//             contrastText: '#FFFFFF',

//         },
//         secondary: {
//             main:'#ffc466',
//             contrastText: '#FFFFFF',
//         }
//     } 
// })
export default function Index(props) {
    const [tab, settab] = useState('list')
    const tabToggle=(key)=>{settab(key)}
    // console.log(`props`, props)
    return (
        <div>
            <Layout>
                {/* <MuiThemeProvider theme={themeButton}> */}
                    {tab==='list'&&<List {...props}  tab={tab} tabToggle={tabToggle}/>}
                    {tab==='add'&&<Detail {...props}  tab={tab} tabToggle={tabToggle}/>}
                    {tab==='detail'&&<Detail {...props}  tab={tab} tabToggle={tabToggle}/>}
                    {tab==='detail_project'&&<DetailProject {...props}  tab={tab} tabToggle={tabToggle}/>}
                    {tab==='detail_rbk'&&<DetailRbk {...props}  tab={tab} tabToggle={tabToggle}/>}
                    {tab==='edit_rbk'&&<DetailRbk {...props}  tab={tab} tabToggle={tabToggle}/>}
                    {tab==='edit'&&<Detail {...props}  tab={tab} tabToggle={tabToggle}/>}
                {/* </MuiThemeProvider> */}
            </Layout>
        </div>
    )
}
