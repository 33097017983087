import {apiCall} from 'service/apiCall'
import {setLoading,modalToggle,setLoadingTable} from './general'
import Cookie from 'universal-cookie'
import {get,isEmpty} from 'lodash'

import * as actionTypes from 'redux/constants/hirarki'
const cookie=new Cookie
const token=cookie.get('login_cookie')
const title_tugas_utama="Tugas utama dari posisi/jabatan terkait."
const title_detail_activitas="Detail aktivitas dari tugas utama di atas."
const title_urutan_prosess="Urutan proses dari detail tugas di atas."

export const setHirarki=(payload)=>dispatch=>{
    dispatch({
        type:actionTypes.SET_HIRARKI,
        payload:payload
    })
}
export const resetHirarki=()=>dispatch=>{
    dispatch({
        type:actionTypes.RESET_HIRARKI,
    })
}
const generateRandomId=()=>{
    return Math.floor(Math.random() * 1000);
}
let periodes=[
    {id:1,text:'Harian',value:228},
    {id:2,text:'Mingguan',value:52},
    {id:3,text:'Bulanan',value:12},
    {id:4,text:'Triwulanan',value:4},
    {id:5,text:'Semesteran',value:2},
    {id:6,text:'Tahunan',value:1},
]
let findPeriodeText=(id)=>{
    let filter=periodes.filter(f=>{
        return f.id===id
    })
    if(filter.length>0){
        return filter[0]
    }else{
        return {id:0,text:'',value:0}
    }
}
let findTypeText=(type,id)=>{
    // console.log(`type`, type)
    if(type!==undefined){
        let filter=type.filter((t)=>{
            return t.id===id
        })
        // console.log(`filter`, filter,type,id)
        if(filter.length>0){
            return filter[0]
        }else{
            return {id:0,text:'',value:0}
        }  
    }
    
}
export const setConfigHirarkiIfTaskNotEmptyAndId1=(tasks,type)=>dispatch=>{
    let new_h=[]
    tasks.map((data,i)=>{
        let new_child=[]
        let new_beban_kerja=0
        data.processes.map((process,i)=>{
            new_beban_kerja+=findPeriodeText(process.periodId).value*process.quantity*process.duration*process.employees
            new_child.push({
                id:process.id,
                title:process.process,
                collapse:true,
                
                uuid:generateRandomId(),
                detail:{
                    periode:process.periodId===0?null:findPeriodeText(process.periodId),
                    kuantitas:process.quantity,
                    durasi:process.duration,
                    pegawai:process.employees,
                    jenis_tugas:process.typeId===0?null:findTypeText(type,process.typeId),
                    keterangan:process.remarks
                },
                child:[]
            })
        })
        if(isEmpty(new_child)){
            new_child.push({
                id:0,
                title:title_detail_activitas,
                collapse:true,
                uuid:generateRandomId(),
                detail:{
                    periode:null,
                    kuantitas:0,
                    durasi:0,
                    pegawai:0,
                    jenis_tugas:null,
                    keterangan:''
                },
                child:[]
            })
        }
        new_h.push({
            id:data.id,
            title:data.task,
            child:new_child,
            no:i+1,
            beban_kerja:new_beban_kerja
        })
    })
    dispatch(setHirarki(new_h))
    return new_h
}

export const setConfigHirarkiIfTaskNotEmptyAndId2=(tasks,type)=>dispatch=>{
    // console.log(`test`, tasks)
    // console.log(`type`, type)
    let new_h=[]
    tasks.map((data,i)=>{
        let new_beban_kerja=0
        //not finale here
        let new_child1=[]
        data.children.map((children,i)=>{
            let new_child2=[]
            children.processes.map((children2,i)=>{
                // console.log(`object`, findPeriodeText(children2.periodId).value*children2.kuantitas*children2.durasi*children2.pegawai)
                new_beban_kerja+=findPeriodeText(children2.periodId).value*children2.quantity*children2.duration*children2.employees
                new_child2.push({
                    id:children2.id,
                    title:children2.process,
                    collapse:true,
                    uuid:generateRandomId(),
                    detail:{
                        periode:children2.periodId===0?null:findPeriodeText(children2.periodId),
                        kuantitas:children2.quantity,
                        durasi:children2.duration,
                        pegawai:children2.employees,
                        jenis_tugas:children2.typeId===0?null:findTypeText(type,children2.typeId),
                        keterangan:children2.remarks
                    },
                    child:[]
                })
            })
            new_child1.push({
                id:children.id,
                title:children.task,
                collapse:true,
                uuid:generateRandomId(),
                detail:null,
                child:new_child2
            })
        })
        if(isEmpty(new_child1)){
            new_child1.push({
                id:0,
                title:title_detail_activitas,
                collapse:true,
                uuid:generateRandomId(),
                detail:null,
                child:[
                    {
                        id:0,
                        title:title_urutan_prosess,
                        collapse:true,
                        uuid:generateRandomId(),
                        detail:{
                            periode:null,
                            kuantitas:0,
                            durasi:0,
                            pegawai:0,
                            jenis_tugas:null,
                            keterangan:''
                        },
                        child:[]
                    }
                ]
            })
        }
        // console.log(`data`, data)
        new_h.push({
            id:data.id,
            title:data.task,
            child:new_child1,
            no:i+1,
            beban_kerja:new_beban_kerja
        })
    })
    // console.log(`hirarki_wla`, new_h)
    dispatch(setHirarki(new_h))
    return new_h
}

export const setConfigHirarkiIfTaskEmptyAndId1=(tasks)=>dispatch=>{
    let new_hirakri=[{
        id:0,
        title:title_tugas_utama,
        child:[
            {
                id:0,
                title:title_detail_activitas,
                collapse:true,
                uuid:generateRandomId(),
                detail:{
                    periode:null,
                    kuantitas:0,
                    durasi:0,
                    pegawai:0,
                    jenis_tugas:null,
                    keterangan:''
                },
                child:[]
            },
            
        ],
        no:1
    }]
    dispatch(setHirarki(new_hirakri))
    return  new_hirakri
}

export const setConfigHirarkiIfTaskEmptyAndId2=()=>dispatch=>{
    let new_hirarki=[{
        id:0,
        title:title_tugas_utama,
        child:[
            {
                id:0,
                title:title_detail_activitas,
                collapse:true,
                uuid:generateRandomId(),
                detail:null,
                child:[
                    {
                        id:0,
                        title:title_urutan_prosess,
                        collapse:true,
                        uuid:generateRandomId(),
                        detail:{
                            periode:null,
                            kuantitas:0,
                            durasi:0,
                            pegawai:0,
                            jenis_tugas:null,
                            keterangan:''
                        },
                        child:[]
                    },
                ]
            },
            
        ],
        no:1
    }]
    dispatch(setHirarki(new_hirarki))
    return new_hirarki
}
export const setTugasUtamaIfId1=(hirarki_wla)=>dispatch=>{
    hirarki_wla.push({
        id:0,
        title:title_tugas_utama,
        child:[
            {
                id:0,
                title:title_detail_activitas,
                collapse:true,
                uuid:generateRandomId(),
                detail:{
                    periode:null,
                    kuantitas:0,
                    durasi:0,
                    pegawai:0,
                    jenis_tugas:null,
                    keterangan:''
                },
                child:[]
            },
            
        ],
        no:hirarki_wla[hirarki_wla.length-1].no+1
    })
    dispatch(setHirarki(hirarki_wla))
    // return hirarki_wla
}

export const setTugasUtamaIfId2=(hirarki_wla)=>dispatch=>{
    hirarki_wla.push({
        id:0,
        title:title_tugas_utama,
        child:[
            {
                id:0,
                title:title_detail_activitas,
                collapse:true,
                uuid:generateRandomId(),
                detail:null,
                child:[
                    {
                        id:0,
                        title:title_urutan_prosess,
                        collapse:false,
                        uuid:generateRandomId(),
                        detail:{
                            periode:null,
                            kuantitas:0,
                            durasi:0,
                            pegawai:0,
                            jenis_tugas:null,
                            keterangan:''
                        },
                        child:[]
                    },
                ]
            },
            
        ],
        no:hirarki_wla[hirarki_wla.length-1].no+1

    })
    dispatch(setHirarki(hirarki_wla))

}

export const setDetailActivitasIfId1=(hirarki_wla,hirarkiIndex1)=>dispatch=>{
    hirarki_wla[hirarkiIndex1].child.push({
        id:0,
        title:title_detail_activitas,
        collapse:true,
        uuid:generateRandomId(),
        detail:{
            periode:null,
            kuantitas:0,
            durasi:0,
            pegawai:0,
            jenis_tugas:null,
            keterangan:''
        },
        child:[]
    })
    dispatch(setHirarki(hirarki_wla))

}
export const setDetailActivitasIfId2=(hirarki_wla,hirarkiIndex1)=>dispatch=>{
    hirarki_wla[hirarkiIndex1].child.push({
        id:0,
        title:title_detail_activitas,
        collapse:true,
        uuid:generateRandomId(),
        detail:null,
        child:[
            {
                id:0,
                title:title_urutan_prosess,
                collapse:false,
                uuid:generateRandomId(),
                detail:{
                    periode:null,
                    kuantitas:0,
                    durasi:0,
                    pegawai:0,
                    jenis_tugas:null,
                    keterangan:''
                },
                child:[]
            }
        ]
    })
    dispatch(setHirarki(hirarki_wla))
}

export const setUrutanProsess=(hirarki_wla,hirarkiIndex1,hirarkiIndex2)=>dispatch=>{
    hirarki_wla[hirarkiIndex1].child[hirarkiIndex2].child.push({
        id:0,
        title:title_urutan_prosess,
        collapse:true,
        uuid:generateRandomId(),
        detail:{
            periode:null,
            kuantitas:0,
            durasi:0,
            pegawai:0,
            jenis_tugas:null,
            keterangan:''
        },
        child:[]
    })
    dispatch(setHirarki(hirarki_wla))
}

export const getPeriod=()=>async dispatch=>{
    dispatch(setLoading(true))
    let datasent={
        url:`/wla/respondent/period`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        dispatch({
            type:actionTypes.GET_PERIODE,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}
export const getType=(slug)=>async dispatch=>{
    dispatch(setLoading(true))
    let datasent={
        url:`/wla/respondent/type${slug}`,
        method:'GET',
        data:{headers:{ 'Authorization': `Bearer ${token}`},}
    }
    let res= await dispatch(apiCall(datasent))
    if(get(res,'status')===200){
        dispatch(setLoading(false))
        dispatch({
            type:actionTypes.GET_TYPE,
            payload:res.data
        })
        return res
        
    }else{
        dispatch(setLoading(false))

    }
}

export const postJawabanResponden=(data)=>async dispatch=>{
    dispatch(setLoading(true))
        let dataReq={
            url:`/wla/respondent`,
            method:'POST',
            data:{data:data,headers:{ 'Authorization': `Bearer ${token}`},}
        }
        let res=await dispatch(apiCall(dataReq))
        if(get(res,'status')===204){
            
            dispatch(setLoading(false))
            
            return res
        }else{
            dispatch(setLoading(false))
        }
}