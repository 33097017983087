import React,{useState} from 'react'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import { useDispatch,useSelector } from 'react-redux'
import { modalToggle } from 'redux/actions/general'
import SearchTable from 'components/SearchTable'
import DataTable from 'components/DataTable'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import * as actionGeneral from 'redux/actions/general'
import * as actionTalenta from 'redux/actions/talenta'
const RenderHead=(props)=>(
    <div className='div-flex div-space-between' style={{alignItems:'flex-end'}}>
        <div className='div-flex div-align-center'>
        <SearchTable
            {...props}
            searchToggle={(value)=>props.searchToggle(value)}
        />
            {}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <p style={{fontFamily:'Lato,sans-serif',fontWeight:'bold',color:'#cccccc',margin:0,fontSize:12}}>Nama File : {props.filename}</p>
        </div>
        <div className='div-flex ' style={{alignItems:'flex-end'}}>
            {props.filter_by_field_value_with_id.length>0&&
            <div >
                <p className='pipeline-filterby'><b>Filter by:</b></p>
                <div style={{display:'flex',flexWrap:'wrap',minWidth:200,maxWidth:400}}>
                    {props.filter_by_field_value_with_id.map((d,i)=>{
                        if(d.value.length>0){
                            return(
                                <p className='pipeline-filterby'>{d.filter.text} : {d.value.map((v)=>v.text).join(' , ')}&nbsp;&nbsp;&nbsp;&nbsp;</p>
                            )
                        }

                    })}
                </div>
            </div>}
            <button onClick={props.filterEmployee} className='card-table__head_btn'>Filter</button>

        </div>
        
        
    </div>
)
const useStyles = makeStyles({
    table: {
      minWidth: 300,
    },
  });
export default function Proyek_employee(props) {
    const classes = useStyles();
    const [isedit, setisedit] = useState(false)
    const [search, setsearch] = useState('')
    const dispatch = useDispatch()
    const talenta = useSelector(state => state.talenta)
    let {list_employee,project_info,filter_by_field_value,filter_by_field_value_with_id}=talenta
    let {getEmployee,profile}=props
    const searchToggle=_.debounce(async (e)=>{
        setsearch(e)
        let data={
            items:filter_by_field_value,
            projectId:project_info.id,
            search:e.length!==""?e:'*'
        }
        getEmployee(`/1/10`,data)
    },1000)
    const togglePagination=(page,pageLength)=>{
        let data={
            items:filter_by_field_value,
            projectId:talenta.project_info.id,
            search:search.length!==""?search:'*'
        }
        getEmployee(`/${page}/${pageLength}`,data)

    }
    const changeFile=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: `Mengganti File Data`,
            modal_component: "change_file",
            modal_size:400,
            modal_type:'change_file',
            modal_data:{afterPost:(data)=>afterPost(data)},
            modal_action:'change_file'
        }))
    }
    const afterPost=()=>{
        let data={
            items:[],
            projectId:talenta.project_id,
            search:'*'
        }
        getEmployee('/1/10',data)
        dispatch(actionGeneral.modalToggleReset())

    }
    const actionFilter=(items)=>{
        let data={
            items:items,
            projectId:talenta.project_info.id,
            search:'*'
        }
        getEmployee(`/1/10`,data)
        dispatch(actionGeneral.modalToggleReset())
    }
    const onSave=()=>{
        if(isedit){
            let payload={
                projectId:project_info.id,
                userId:profile.id,
                items:[]
            }
            list_employee.rows.map((row)=>{
                row.items.map((item)=>{
                    payload.items.push(item)
                })
            })
            dispatch(actionTalenta.postEmployee(payload))
            setisedit(false)

            console.log(`list_employee`, payload)
        }else{
            setisedit(true)
        }
    }
    const filterEmployee=async ()=>{
        let res=await dispatch(actionTalenta.getFilterField(project_info.id))
        if(res){
            dispatch(actionGeneral.modalToggle({
                modal_open: true,
                modal_title: `Filter Karyawan`,
                modal_component: "filter_by_field",
                modal_size:450,
                modal_type:'filter_by_field',
                modal_data:{
                    action:(items)=>actionFilter(items),
                    
                },
                modal_action:'filter_by_field'
            }))
        }
        
    }
    const onInput=_.debounce(async (value,i1,i2)=>{
        
        list_employee.rows[i1].items[i2].text=value
        dispatch(actionTalenta.setTalenta({list_employee:list_employee}))
    },1000)
    // console.log(`filter_by_field_value_with_id`, filter_by_field_value_with_id)
    return (
        <div>
            <div className='head-section'>
                <div className='div-flex div-align-center' >
                    <h4 style={{fontWeight:'bold'}}>Daftar Data Karyawan</h4>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {isedit&&<><p style={{fontFamily:'Lato,sans-serif',fontWeight:'bold',color:'#cccccc',margin:0,fontSize:14}}>Nama File : <span style={{color:'#252525'}}>{list_employee.filename}</span></p>
                    &nbsp;&nbsp;&nbsp;
                    <p onClick={changeFile} style={{cursor:'pointer',fontFamily:'Lato,sans-serif',fontWeight:'bold',color:'#f44336',margin:0,fontSize:14}}>Tambah Data</p></>}
                </div>
                <div className='div-flex'>
                    <Button  onClick={onSave}  className='btn-remove-capital btn-rounded' variant='contained' color="primary">{isedit?"Simpan":"Edit Data"}</Button>
                </div>
            </div>
            <DataTable
                className={classes.table}
                head={list_employee.headers}
                body={()=>{
                    return list_employee.rows.map((d,i)=>(
                        <TableRow >
                            {d.items.map((item,i2)=>(
                                <TableCell suppressContentEditableWarning={true} onInput={e =>onInput(e.currentTarget.textContent,i,i2)} contenteditable={`${isedit}`}>{item.text}</TableCell>
                            ))}
                        </TableRow>
                    ))
                }}
                cardHead={<RenderHead filename={list_employee.filename} filter_by_field_value_with_id={filter_by_field_value_with_id} filterEmployee={filterEmployee} {...props}  searchToggle={searchToggle} />}
                loading={false}
                pagination={list_employee.info}
                togglePagination={togglePagination}
            />
        </div>
    )
}
