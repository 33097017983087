import React,{useState,useEffect} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import {Button,TextField,Collapse} from '@material-ui/core'
import Edit from 'assets/icon/Edit-all-blue.svg'
import EditDisable from 'assets/icon/EditDisable.svg'
import close from 'assets/icon/close.svg'
import CloseDisable from 'assets/icon/CloseDisable.svg'
import * as hirarkiAction from 'redux/actions/hirarki'
import {modalToggle,modalToggleReset} from 'redux/actions/general'
import HirarkiItemSub from './HirarkiItemSub'
import {isEmpty,toPlainObject} from 'lodash'
import _ from 'lodash'
export default function HirarkiItem(props) {
    let {classes,is_edited,is_verify}=props
    const dispatch = useDispatch()
    const [tugasUtamaModal, settugasUtamaModal] = useState({})
    const [detailTugasUtamaModal, setDetailTugasUtamaModal] = useState({})
    const [urutanProsesModal, seturutanProsesModal] = useState({})
    const [collapse1, setcollapse1] = useState({})
    const [collapse2, setcollapse2] = useState({})
    const [modaltitle, setmodaltitle] = useState('')
    const [test, settest] = useState(0)
    const hirarki = useSelector(state => state.hirarki)
    let {hirarki_wla,periode,type}=hirarki
    useEffect(() => {
        // console.log(`trigger from hirarki`,hirarki_wla)
        // if(isEmpty(hirarki_wla)){
            setHirarkiWlaConfig()
        // }else{
        //     console.log(`props.tasks`, props.tasks)
        // }
    }, [hirarki_wla])
    const setHirarkiWlaConfig=async ()=>{
        // console.log('trigger',hirarki_wla)

        // let res
        // if(!isEmpty(props.tasks)){
        //     if(props.hirarki_id===1){
        //         res =await dispatch(hirarkiAction.setConfigHirarkiIfTaskNotEmptyAndId1(props.tasks))
        //     }else{

        //         res=await dispatch(hirarkiAction.setConfigHirarkiIfTaskNotEmptyAndId2(props.tasks))
        //     }
        // }else{
        //     if(props.hirarki_id===1){
        //         res=await dispatch(hirarkiAction.setConfigHirarkiIfTaskEmptyAndId1())
        //     }else{
        //         res=await dispatch(hirarkiAction.setConfigHirarkiIfTaskEmptyAndId2())
        //     }
        // }
        configCollapseFirstReload()
    }
    const configCollapseFirstReload=()=>{
        let new_coll=[]
        hirarki_wla.map((data)=>{
            data.child.map((child1,i)=>{
                child1.child.map((child2,i2)=>{
                    // if(i2===0){
                        new_coll.push({key:child2.uuid,value:true})
                    // }
                })
                // if(i===0){
                    new_coll.push({key:child1.uuid,value:true})
                    
                // }
            })
        })
        let objcoll=_.chain(new_coll)
                .keyBy('key')
                .mapValues('value')
                .value();
        setcollapse1(objcoll)
    }
    // console.log(`collapse1`, collapse1)
    const renderMarginSub=(sub)=>{
        if(sub===1){
            return 0
        }else if(sub===2){
            return 35
        }else if(sub===3){
            return 40
        }
    }
    const setcollapse=(hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)=>{
        if(hirarkiIndex3==undefined){
            hirarki_wla[hirarkiIndex1].child[hirarkiIndex2].collapse=!hirarki_wla[hirarkiIndex1].child[hirarkiIndex2].collapse;
        }else{
            hirarki_wla[hirarkiIndex1].child[hirarkiIndex2].child[hirarkiIndex3].collapse=!hirarki_wla[hirarkiIndex1].child[hirarkiIndex2].child[hirarkiIndex3].collapse;
        }
        dispatch(hirarkiAction.setHirarki(hirarki_wla))
    }
    const onSaveDeskripsiTugasUtama=(hirarkiIndex1)=>{
        hirarki_wla[hirarkiIndex1].title=modaltitle
        dispatch(hirarkiAction.setHirarki(hirarki_wla))
        showTugasUtamaModal({},'')
    }
    const onSaveDetailDeskripsiTugasUtama=(hirarkiIndex1,hirarkiIndex2)=>{
        hirarki_wla[hirarkiIndex1].child[hirarkiIndex2].title=modaltitle
        dispatch(hirarkiAction.setHirarki(hirarki_wla))
        showDetailAktivitasModal({},'')
    }
    const onSaveUrutanProssess=(hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)=>{
        hirarki_wla[hirarkiIndex1].child[hirarkiIndex2].child[hirarkiIndex3].title=modaltitle
        dispatch(hirarkiAction.setHirarki(hirarki_wla))
        showUrutanProsess({},'')
    }
    const tambahTugasUtama=async ()=>{
        if(props.hirarki_id==1){
            dispatch(hirarkiAction.setTugasUtamaIfId1(hirarki_wla))
        }else{
            dispatch(hirarkiAction.setTugasUtamaIfId2(hirarki_wla))
        }
        configCollapseFirstReload()
    }
    const tambahDetailActivitas=(hirarkiIndex1,hirarkiIndex2)=>{
        if(props.hirarki_id===1){
            dispatch(hirarkiAction.setDetailActivitasIfId1(hirarki_wla,hirarkiIndex1))
        }else{
            dispatch(hirarkiAction.setDetailActivitasIfId2(hirarki_wla,hirarkiIndex1))
        }
        configCollapseFirstReload()
    }
    const tambahUrutanProsess=(hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)=>{
        dispatch(hirarkiAction.setUrutanProsess(hirarki_wla,hirarkiIndex1,hirarkiIndex2))
        configCollapseFirstReload()
    }
    const showDeleteModal=(action)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Hirarki',
                action:()=>action(),
                msg:`<p>Apakah anda yakin menghapus ? </p>`
            },
            modal_action:'delete_hirarki'
        }))
    }
    const deleteTugasUtama=(index)=>{
        if(is_edited){
            let actionDel=()=>{
                hirarki_wla.splice(index,1)
                dispatch(hirarkiAction.setHirarki(hirarki_wla))
                dispatch(modalToggleReset())
            }
            showDeleteModal(actionDel)
        }
        
    }
    const deleteDetailAktivitas=(hirarkiIndex1,hirarkiIndex2)=>{
        if(is_edited){
            let actionDel=()=>{
                hirarki_wla[hirarkiIndex1].child.splice(hirarkiIndex2,1)
                dispatch(hirarkiAction.setHirarki(hirarki_wla))
                dispatch(modalToggleReset())
            }
            showDeleteModal(actionDel)
        }
        
    }
    const deleteUruttanProsess=(hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)=>{
        if(is_edited){
            let actionDel=()=>{
                hirarki_wla[hirarkiIndex1].child[hirarkiIndex2].child.splice(hirarkiIndex3,1)
                dispatch(hirarkiAction.setHirarki(hirarki_wla))
                

                dispatch(modalToggleReset())
            }
            showDeleteModal(actionDel)
        }
        
    }
    // console.log('eyoooo')
    const showTugasUtamaModal=(payload,text)=>{
        if(is_edited){
            setmodaltitle(text)
            settugasUtamaModal(payload)
        }
    }
    const showDetailAktivitasModal=(payload,text)=>{
        if(is_edited){
            setmodaltitle(text)
            setDetailTugasUtamaModal(payload)
        }
    }
    const showUrutanProsess=(payload,text)=>{
        if(is_edited){
            setmodaltitle(text)
            seturutanProsesModal(payload)
        }
    }
    const onChangeSub1=(key,value,hirarkiIndex1,hirarkiIndex2)=>{
        hirarki_wla[hirarkiIndex1].child[hirarkiIndex2].detail[key]=value
        dispatch(hirarkiAction.setHirarki(hirarki_wla))
    }
    const onChangeSub2=(key,value,hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)=>{
        hirarki_wla[hirarkiIndex1].child[hirarkiIndex2].child[hirarkiIndex3].detail[key]=value
        dispatch(hirarkiAction.setHirarki(hirarki_wla))
    }
    const renderIconEdit=()=>{
        if(is_edited){
            return Edit
        }else{
            return EditDisable
        }
    }
    const renderIconClose=()=>{
        if(is_edited){
            return close
        }else{
            return CloseDisable
        }
    }
    // console.log(`hirarki_wla`, hirarki_wla)
    return (
        <div>
            {hirarki_wla.map((hirarki1,hirarkiIndex1)=>(
                <div className='hirarki-container' key={hirarkiIndex1}>
                    {tugasUtamaModal[hirarkiIndex1]&&<div className='backdoor2' onClick={()=>showTugasUtamaModal({},'')}></div>}
                    <div className='hirarki-top'>
                        <div className='hirarki-text-padding div-flex div-align-start'>
                            <p className='hirarki-number'>{hirarki1.no}.</p>
                            <div className='position-relative'>
                                <p className='hirarki-text-item'>{hirarki1.title}</p>
                                {tugasUtamaModal[hirarkiIndex1]&&
                                <div className='tugas-utama-modal'>
                                    <TextField
                                        label="Deskripsi Tugas Utama"
                                        variant='outlined'
                                        type='text'
                                        value={modaltitle}
                                        size='small'
                                        className={classes.textField}
                                        onChange={(e)=>setmodaltitle(e.target.value)}
                                    />
                                    &nbsp;&nbsp;
                                    <Button style={{height:35,width:80}} onClick={()=>onSaveDeskripsiTugasUtama(hirarkiIndex1)} className='btn-remove-capital btn-rounded' size='small' variant='contained' color='primary'>Simpan</Button>
                                </div>}
                            </div>
                            <img onClick={()=>showTugasUtamaModal({[hirarkiIndex1]:!tugasUtamaModal[hirarkiIndex1]},hirarki1.title)} src={renderIconEdit()} className='hirarki-icon'/>
                            
                            {hirarkiIndex1!==0&&<img onClick={()=>deleteTugasUtama(hirarkiIndex1)} src={renderIconClose()} className='hirarki-icon'/>}
                        </div>
                    </div>
                    {hirarki1.child.map((hirarki2,hirarkiIndex2)=>(
                        <div key={hirarkiIndex2}>
                             {detailTugasUtamaModal[parseInt(`${hirarkiIndex1}${hirarkiIndex2}`)]&&<div className='backdoor2' onClick={()=>showDetailAktivitasModal({},'')}></div>}
                            <HirarkiItemSub
                                marginSize={1}
                                classes={classes}
                                onSaveDetailDeskripsiTugasUtama={onSaveDetailDeskripsiTugasUtama}
                                renderMarginSub={renderMarginSub}
                                setDetailTugasUtamaModal={setDetailTugasUtamaModal}
                                detailTugasUtamaModal={detailTugasUtamaModal}
                                urutanProsesModal={urutanProsesModal}
                                seturutanProsesModal={seturutanProsesModal}
                                hirarki1={hirarki1}
                                hirarki2={hirarki2}
                                hirarkiIndex1={hirarkiIndex1}
                                hirarkiIndex2={hirarkiIndex2}
                                setcollapse={setcollapse}
                                tambahDetailActivitas={tambahDetailActivitas}
                                collapse1={collapse1}
                                collapse2={collapse2}
                                setcollapse1={setcollapse1}
                                setcollapse2={setcollapse2}
                                deleteDetailAktivitas={deleteDetailAktivitas}
                                tambahUrutanProsess={tambahUrutanProsess}
                                deleteUruttanProsess={deleteUruttanProsess}
                                showDetailAktivitasModal={showDetailAktivitasModal}
                                modaltitle={modaltitle}
                                setmodaltitle={setmodaltitle}
                                showUrutanProsess={showUrutanProsess}
                                onSaveUrutanProssess={onSaveUrutanProssess}
                                periode={periode}
                                type={type}
                                onChangeSub1={onChangeSub1}
                                onChangeSub2={onChangeSub2}
                                is_edited={is_edited}
                                renderIconClose={renderIconClose}
                                renderIconEdit={renderIconEdit}
                            />
                            
                        </div>
                        
                    ))}
                    {hirarkiIndex1===hirarki_wla.length-1&&is_edited&&<div style={{width:'100%',marginTop:20}}>
                        <div className='btn-tambah-tugas-utama' onClick={tambahTugasUtama}>
                            <p>Tambah Tugas Utama</p>
                        </div>
                    </div>}
                </div>
            ))}
        </div>
    )
}
