import React,{useState,useEffect} from 'react'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TableCell,TableRow,TableBody,TableContainer,Paper} from '@material-ui/core'
import Skeleton from 'components/Skeleton'
import { TablePagination } from '@trendmicro/react-paginations';
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import SearchTable from 'components/SearchTable'
import Modal from '../modal'
import TextField from 'components/TextField'
import * as actionTalenta from 'redux/actions/talenta'
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import ContentEditable from 'components/ContentEditable'
const useStyles = makeStyles(theme => ({
    paper: {
        boxShadow:'unset'
      },
}));

const EditableCell=(props)=>{
    let {onInput,onKeyPress,i3,i2,i,isedit,c,item}=props
    const onChange=(e)=>{
        console.log(`e`, e)
    }
    return(
        // <TableCell suppressContentEditableWarning={true}  onInput={(e)=> onInput(e.currentTarget.textContent,i,i2,i3,'y')} onKeyPress={onKeyPress}  key={i3} style={{backgroundColor:'#dbe9ff',textAlign:'left',color:item.totalY===100?'#252525':'red'}} align="center" disabled={true} contentEditable={`${isedit[i]}`} placeholder="0">{c.number}</TableCell>
        <TableCell    key={i3} style={{backgroundColor:'#dbe9ff',textAlign:'left',color:item.totalY===100?'#252525':'red'}} align="center" >
            <ContentEditable 
                html={c.number.toString()} 
                // onBlur={handleBlur} 
                onChange={(e)=>onInput(e.target.value,i,i2,i3,'y')} 
                // onChange={onChange} 
            />
        </TableCell>
    )
}
export default function Proyek_bobot(props) {
    const classes=useStyles()
    const dispatch = useDispatch()
    const [modal_bobot, setmodal_bobot] = useState({})
    const [isedit, setisedit] = useState({})
    const [new_grade, setnew_grade] = useState('')
    const [error_grade, seterror_grade] = useState('')
    const [test, settest] = useState('')
    let {getBobot,profile,bobot}=props
    // let {bobot}=talenta
    useEffect(() => {
        // bobot.map
        console.log(`bobot`, bobot)
    }, [])
    const onSave=(data,i)=>{
        if(isedit[i]){
            let new_item=[]
            data.items.map((item)=>{
                item.x.map((x)=>{
                    new_item.push(x)
                })
                item.y.map((y)=>{
                    new_item.push(y)
                })
            })
            let payload={
                items:new_item,
                userId:profile.id
            }
            dispatch(actionTalenta.saveBobot(payload))
            setisedit({[i]:!isedit[i]})
            // console.log('b',payload)
        }else{
            setisedit({[i]:!isedit[i]})
        }
    }
    const onSaveGrade=async (bobot)=>{
        let data={
            id:bobot.column.id,
            text:new_grade,
            parentLabel:bobot.parentLabel
        }
        let res=await dispatch(actionTalenta.postGrade(data))
        if(res){
            setmodal_bobot({})
            setnew_grade('')
            getBobot()
        }
    }
    const testSet=()=>{
        
    }
    // const onInput=_.debounce(async (value,i1,i2,i3,key)=>{
    //     bobot[i1].items[i2][key][i3].number=parseInt(value)
    //     let totalX=0
    //     let totalY=0
    //     bobot[i1].items[i2].x.map((d)=>{
    //         totalX+=d.number
    //     })
    //     bobot[i1].items[i2].y.map((d)=>{
    //         totalY+=d.number
    //     })
    //     bobot[i1].items[i2].totalX=totalX
    //     bobot[i1].items[i2].totalY=totalY
    //     dispatch(actionTalenta.setTalenta({bobot:bobot}))
    //     totalX=0
    //     totalY=0
    // },1000)
    const onInput=async (value,i1,i2,i3,key)=>{
        bobot[i1].items[i2][key][i3].number=parseInt(value)
        let totalX=0
        let totalY=0
        bobot[i1].items[i2].x.map((d)=>{
            totalX+=d.number
        })
        bobot[i1].items[i2].y.map((d)=>{
            totalY+=d.number
        })
        bobot[i1].items[i2].totalX=totalX
        bobot[i1].items[i2].totalY=totalY
        dispatch(actionTalenta.setTalenta({bobot:bobot}))
    }
    
    const onChangeNewGrade=(value,items)=>{
        setnew_grade(value)
        let filter=items.filter(d=>{
            return d.label.toLowerCase()===value.toLowerCase()
        })
        if(filter.length>0){
            seterror_grade('Nama sudah digunakan, anda dapat menggunakan nama lainnya')
        }else{
            seterror_grade('')
        }
    }
    const onKeyPress=(e)=>{
        if(e.which===13){
            e.preventDefault()
        }
    }
    const renderError=(data)=>{
        // console.log(`data`, data)
        let findnot100X=data.items.filter(d=>{
            return d.totalX!==100
        })
        let findnot100Y=data.items.filter(d=>{
            return d.totalY!==100
        })
        if(findnot100X.length>0||findnot100Y.length>0){
            return true
        }else{
            return false
        }
        // return false
       
    }
    const renderDisable=(i,data)=>{
        if(!isedit[i]){
            return false
        }else{
            if(!renderError(data)){
                return false
            }else{
                return true
            }
        }
    }
    return (
        <div>
            {bobot.map((data,i)=>(
            <div key={i}>
            {modal_bobot[i]&&<div onClick={()=>setmodal_bobot({[i]:false})} className='backdoor'></div>}

                <div className='head-section'>
                    <div className='div-flex div-align-center' >
                        <h4 style={{fontWeight:'bold'}}>{data.title}</h4>
                    </div>
                </div>
                
                <div className='card-content'>
                <div className='card-table'>
                    <div className='card-table___head'>
                        <div className='div-flex div-space-between' style={{alignItems:'flex-end'}}>
                            <div className='div-flex div-align-center'>
                           
                            </div>
                            <div className='div-flex div-align-center'>
                                {renderError(data)&&<p style={{color:'red',margin:0}}>Nilai Bobot harus 100%</p>}
                                &nbsp;&nbsp;
                                <div style={{position:'relative'}}>
                                <Button onClick={()=>setmodal_bobot({[i]:!modal_bobot[i]})} variant="text" className="btn-remove-capital" color="primary">Tambah Grade</Button>

                                {modal_bobot[i]&&<Modal
                                    modal_title={`Tambah Grade ${data.title}`}
                                    modal_size={400}
                                    modalToggleReset={()=>setmodal_bobot(false)}
                                    body={
                                        <div style={{padding:'0 20px 15px 20px'}}>
                                            <TextField
                                                label="Nama Grade"
                                                onChange={(e)=>onChangeNewGrade(e.target.value,data.items)}
                                                // value={state.nama}
                                                color='primary'
                                                variant='outlined'
                                                size='small'
                                                name='name'
                                                style={{marginBottom:20}}
                                            />
                                            {error_grade!==""&&<p style={{margin:0,marginBottom:10,color:'red',fontSize:12}}>{error_grade}</p>}
                                            <div style={{textAlign:'right'}}>
                                                <Button onClick={()=>onSaveGrade(data)} disabled={error_grade!==""||new_grade===""} className='btn-rounded btn-remove-capital' color="primary" variant="contained" size="small">Simpan</Button>
                                            </div>
                                        </div>
                                    }
                                />}
                                </div>
                                &nbsp;&nbsp;
                                <Button disabled={renderDisable(i,data)} onClick={()=>onSave(data,i)} variant="text" className="btn-remove-capital" color="primary">
                                    {isedit[i]?"Simpan":"Edit Bobot"}
                                </Button>
                            </div>                        
                            
                        </div>
                    </div>
                    <br/>
                    <div className='card-table__content'>
                        <TableContainer className={classes.paper} component={Paper}>
                            <Table  size="small" aria-label="a dense table">
                                <TableHead >
                                <TableRow>
                                    <TableCell rowSpan={2} width={500}>{data.column.text}</TableCell>
                                    <TableCell colSpan={data.yHeaders.length} align="center" style={{backgroundColor:'#dbe9ff'}}>{data.yAxis}</TableCell>
                                    <TableCell colSpan={data.xHeaders.length} align="center" style={{backgroundColor:'#ffdbe6'}}>{data.xAxis}</TableCell>
                                    
                                </TableRow>
                                <TableRow>
                                    {data.yHeaders.map((h,i)=>(
                                        <TableCell align="center"  key={i} width={500} style={{backgroundColor:'#dbe9ff'}}>{h}</TableCell>

                                    ))}
                                    {data.xHeaders.map((h,i)=>(
                                        <TableCell align="center" key={i} width={500} style={{backgroundColor:'#ffdbe6'}}>{h}</TableCell>

                                    ))}
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.items.map((item,i2)=>(
                                        <TableRow key={i2}>
                                            <TableCell>{item.label}</TableCell>
                                            {item.y.map((c,i3)=>(
                                                <TableCell  key={i3} style={{backgroundColor:'#dbe9ff',textAlign:'left',color:item.totalY===100?'#252525':'red'}} align="center" >
                                                    <ContentEditable 
                                                        disabled={!isedit[i]}
                                                        html={isNaN(c.number)?'0':c.number.toString()} 
                                                        onKeyDown={onKeyPress}
                                                        onPaste={(e)=>{
                                                            var clipboardData, pastedData;
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            clipboardData = e.clipboardData || window.clipboardData;
                                                            pastedData = clipboardData.getData('Text');
                                                            onInput(parseInt(pastedData),i,i2,i3,'y')
                                                        }}
                                                        onChange={(e)=>onInput(e.target.value,i,i2,i3,'y')} 
                                                    />
                                                </TableCell>
                                            ))}
                                            {item.x.map((c,i3)=>(
                                                 <TableCell  key={i3} style={{backgroundColor:'#ffdbe6',textAlign:'left',color:item.totalX===100?'#252525':'red'}} align="center" >
                                                    <ContentEditable 
                                                        disabled={!isedit[i]}
                                                        html={isNaN(c.number)?'0':c.number.toString()} 
                                                        onPaste={(e)=>{
                                                            var clipboardData, pastedData;
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            clipboardData = e.clipboardData || window.clipboardData;
                                                            pastedData = clipboardData.getData('Text');
                                                            onInput(parseInt(pastedData),i,i2,i3,'y')
                                                        }}
                                                        onChange={(e)=>onInput(e.target.value,i,i2,i3,'x')} 
                                                    />
                                                </TableCell>

                                            ))}
                                        </TableRow>
                                    ))}
                                    
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className='card-table__pagination'>
                        
                    </div>
                    </div>
                </div>
            </div>
            <br/>
            </div>
        ))}

        </div>
    )
}
