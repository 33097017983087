import React from 'react'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment
    ,Table,TableHead,TableRow,TableBody,TableContainer,Paper} from '@material-ui/core'
import { TablePagination } from '@trendmicro/react-paginations';
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import MuiTableCell from "@material-ui/core/TableCell";
import * as actionGeneral from 'redux/actions/general'
import * as actionTalenta from 'redux/actions/talenta'
import { useDispatch,useSelector } from 'react-redux';
const useStyles = makeStyles(theme => ({
    paper: {
        boxShadow:'unset'
      },
}));
const TableCell = withStyles({
    root: {
      borderBottom: "none",
    }
  })(MuiTableCell);

export default function Talent_pool(props) {
    const dispatch = useDispatch()
    const classes=useStyles()
    const talenta = useSelector(state => state.talenta)
    let {list_map_talenta,project_info,choosen_template}=talenta
    let {getListMapTalenta,profile,renderAction,jenis_sebaran}=props
    const afterPost=(data)=>{
        // dispatch(actionGeneral.modalToggleReset())

        getListMapTalenta()
        let payload={
            items:[],
            projectId:project_info.id,
            search:'*'
        }
        renderAction(payload,jenis_sebaran)
    }
    const addTalentMap=()=>{
        dispatch(actionGeneral.modalToggle({
            modal_open: true,
            modal_title: `Klasifikasi Peta Talenta`,
            modal_component: "talent_map",
            modal_size:850,
            modal_type:'talent_map',
            modal_data:{
                afterPost:()=>afterPost()
            },
            modal_action:'talent_map'
        }))
    }
    const getChooseTemplate=async (slug,item)=>{
        // alert('hello')
        getListMapTalenta()
        let res=await dispatch(actionTalenta.chooseTemplate(slug))
        if(res){
            dispatch(actionTalenta.setTalenta({template_active:item}))
            dispatch(actionGeneral.modalToggleReset())
        }
    }
    const chooseTemplate=async ()=>{
        let res=await dispatch(actionTalenta.getListTemplate(`/${profile.id}/${0}`))
        if(res){
            dispatch(actionGeneral.modalToggle({
                modal_open: true,
                modal_title: `Pilih Template Klasifikasi Peta Talenta`,
                modal_component: "choose_template",
                modal_size:500,
                modal_type:'choose_template',
                modal_data:{
                    actions:(slug,item)=>getChooseTemplate(slug,item)
                },
                modal_action:'choose_template'
            }))
        }
        
    }
    return (
        <div>
            <div className='div-flex div-space-between div-align-center'>
                <p style={{margin:0,fontSize:13,fontWeight:'bold',color:'#777777'}}>Data Template : {choosen_template!==""?choosen_template:'Belum Mengggunakan'} &nbsp;&nbsp;<span onClick={chooseTemplate} style={{color:'#00a1ed',cursor:'pointer'}}>{choosen_template!==""?'Ganti':"Pilih Template"}</span></p>
                <Button  onClick={()=>addTalentMap()}  className='btn-remove-capital btn-rounded' variant='contained' color="primary">
                    {list_map_talenta.length>0?'Edit Data':'Klasifikasi Peta Talenta'}
                </Button>
            </div>
            <br/>
            <div className='card-content'>
                <div className='card-table'>
                    <div className='card-table__content'>
                        <TableContainer className={classes.paper} component={Paper}>
                            <Table  size="small" aria-label="a dense table">
                                <TableHead style={{borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                                    <TableRow>
                                        <TableCell style={{fontWeight:600,fontSize:12,}} rowSpan={2} >No.</TableCell>
                                        <TableCell style={{fontWeight:600,fontSize:12,}} rowSpan={2} >Nama <i>talent pool</i></TableCell>
                                        <TableCell style={{fontWeight:600,fontSize:12,}} colSpan={2} align="center">{project_info.xAxis}</TableCell>
                                        <TableCell style={{fontWeight:600,fontSize:12,}} rowSpan={2} ></TableCell>

                                        <TableCell style={{fontWeight:600,fontSize:12,}} colSpan={2} align="center">{project_info.yAxis}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell  style={{borderTop:'1px solid rgba(224, 224, 224, 1)',fontWeight:600,fontSize:12,}}>Nilai batas bawah</TableCell>
                                        <TableCell  style={{borderTop:'1px solid rgba(224, 224, 224, 1)',fontWeight:600,fontSize:12,}}>Nilai batas atas</TableCell>
                                        <TableCell  style={{borderTop:'1px solid rgba(224, 224, 224, 1)',fontWeight:600,fontSize:12,}}>Nilai batas bawah</TableCell>
                                        <TableCell  style={{borderTop:'1px solid rgba(224, 224, 224, 1)',fontWeight:600,fontSize:12,}}>Nilai batas atas</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {list_map_talenta.map((d,i)=>(
                                        <TableRow key={i} style={{borderBottom:'1px solid rgba(224, 224, 224, 1)'}}>
                                            <TableCell>{i+1}</TableCell>
                                            <TableCell style={{fontSize:12}}>
                                                <div className='div-flex div-align-center'>
                                                    <div style={{width:18,height:18,backgroundColor:d.bgColor}}></div> 
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <p style={{margin:0}}>{d.label}</p>
                                                </div>
                                            </TableCell>
                                            <TableCell align="center">{d.minX}</TableCell>
                                            <TableCell align="center">{d.maxX}</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align="center">{d.minY}</TableCell>
                                            <TableCell align="center">{d.maxY}</TableCell>
                                        </TableRow>
                                    ))}
                                    
                                    
                                    {/* {loading?<Skeleton count={head.length}/>:body()} */}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className='card-table__pagination'>
                        {/* {pagination!==null&&
                        <TablePagination
                                className="card-pagination"
                                type="reduced"
                                page={pagination.page}
                                pageLength={pagination.perPage}
                                totalRecords={pagination.total}
                                totalRecords={pagination.total}
                                onPageChange={({ page, pageLength }) => {
                                    togglePagination(page,pageLength)
                                }}
                                prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                                nextPageRenderer={() => <img src={CevronRight}/>}
                            />
                        } */}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
