import React from 'react'
import './style.css'
import Logo from 'assets/image/OE.png'
export default function Index(props) {
    // console.log(`props.profile`, props.profile)
    return (
        <nav>
            <div className='hamburger' >
                <div className='hamburger__list'/>
                <div className='hamburger__list'/>
                <div className='hamburger__list'/>
            </div>
            <div className='logo' onClick={()=>window.location.assign('/')}>
                <img src={props.profile.logo_url?props.profile.logo_url:Logo} style={{width:100}}/>
                {/* <div className='vertical-line'></div> */}
                {/* <h4>Knowledge Center</h4> */}
            </div>
        </nav>
    )
}
