import React,{useState} from 'react'
import {useDispatch,useSelector} from 'react-redux'
import {Button,TextField,Collapse} from '@material-ui/core'
import Edit from 'assets/icon/Edit-all-blue.svg'
import ChevronDownBlue from 'assets/icon/Polygon.svg'
import close from 'assets/icon/close.svg'
import * as hirarkiAction from 'redux/actions/hirarki'
import HirarkiForm from './HirarkiForm'

const HirarkiItemSub=(props)=>{
    let {
        classes,
        hirarki1,
        hirarki2,
        hirarkiIndex1,
        hirarkiIndex2,
        hirarkiIndex3,
        marginSize,
        setcollapse,
        onSaveDetailDeskripsiTugasUtama,
        renderMarginSub,
        setDetailTugasUtamaModal,
        detailTugasUtamaModal,
        urutanProsesModal,
        seturutanProsesModal,
        tambahDetailActivitas,
        collapse1,
        collapse2,
        setcollapse1,
        setcollapse2,
        deleteDetailAktivitas,
        tambahUrutanProsess,
        deleteUruttanProsess,
        showDetailAktivitasModal,
        modaltitle,
        setmodaltitle,
        showUrutanProsess,
        onSaveUrutanProssess,
        periode,
        type,
        onChangeSub1,
        onChangeSub2,
        is_edited,
        renderIconClose,
        renderIconEdit
    }=props

    return(
            <div className='hirarki-sub' style={{marginLeft:renderMarginSub(marginSize)}}>
                <div className=' hirarki-text-padding div-flex div-align-start'>
                <div>
                    <img onClick={()=>setcollapse1({...collapse1,[hirarki2.uuid]:!collapse1[hirarki2.uuid]})} src={ChevronDownBlue} className={`chevron-hirarki  ${collapse1[hirarki2.uuid]&&'chevron-hirarki-active'}`} />
                </div>
                <p className='hirarki-number'>{hirarki1.no}.{hirarkiIndex2+1}.</p>
                <div className='position-relative'>
                    <p className='hirarki-text-item'>{hirarki2.title}</p>
                    {detailTugasUtamaModal[parseInt(`${hirarkiIndex1}${hirarkiIndex2}`)]&&
                    <div className='detail-tugas-utama-modal'>
                        <TextField
                            label="Detail Deskripsi Tugas Utama"
                            variant='outlined'
                            type='text'
                            value={modaltitle}
                            size='small'
                            className={classes.textField}
                            onChange={(e)=>setmodaltitle(e.target.value)}
                        />
                        &nbsp;&nbsp;
                        <Button style={{height:35,width:80}} onClick={()=>onSaveDetailDeskripsiTugasUtama(hirarkiIndex1,hirarkiIndex2)} className='btn-remove-capital btn-rounded' size='small' variant='contained' color='primary'>Simpan</Button>
                    </div>}
                </div>
                <img onClick={()=>showDetailAktivitasModal({...detailTugasUtamaModal,[parseInt(`${hirarkiIndex1}${hirarkiIndex2}`)]:!detailTugasUtamaModal[parseInt(`${hirarkiIndex1}${hirarkiIndex2}`)]},hirarki2.title)} src={renderIconEdit()} className='hirarki-icon'/>
                {hirarkiIndex2!==0&&<img onClick={()=>deleteDetailAktivitas(hirarkiIndex1,hirarkiIndex2)} src={renderIconClose()} className='hirarki-icon'/>}
            </div>
            <Collapse in={collapse1[hirarki2.uuid]}>
                {hirarki2.detail!==null&&
                    <div className='content-sub'>
                        <HirarkiForm
                            classes={classes}
                            periode={periode}
                            type={type}
                            onChangeSub={onChangeSub1}
                            hirarkiIndex1={hirarkiIndex1}
                            hirarkiIndex2={hirarkiIndex2}
                            detail={hirarki2.detail}
                            is_edited={is_edited}
                        />
                    </div>
                }
                <div className='content-sub2'>
                    {hirarki2.child.map((hirarki3,hirarkiIndex3)=>(
                        <div className='hirarki-sub' key={hirarkiIndex3}>
                            {urutanProsesModal[parseInt(`${hirarkiIndex1}${hirarkiIndex2}${hirarkiIndex3}`)]&&<div className='backdoor2' onClick={()=>showUrutanProsess({},'')}></div>}
                            <div className=' hirarki-text-padding div-flex div-align-start'>
                                <div>
                                    <img onClick={()=>setcollapse1({...collapse1,[hirarki3.uuid]:!collapse1[hirarki3.uuid]})} src={ChevronDownBlue} className={`chevron-hirarki ${collapse1[hirarki3.uuid]&&'chevron-hirarki-active'}`} />
                                </div>
                                <p className='hirarki-number'>{hirarki1.no}.{hirarkiIndex2+1}.{hirarkiIndex3+1}</p>
                                <div className='position-relative'>
                                    <p className='hirarki-text-item'>{hirarki3.title}</p>
                                    {urutanProsesModal[parseInt(`${hirarkiIndex1}${hirarkiIndex2}${hirarkiIndex3}`)]&&
                                    <div className='detail-tugas-utama-modal'>
                                        <TextField
                                            label="Urutan prosess deskripsi"
                                            variant='outlined'
                                            type='text'
                                            value={modaltitle}
                                            size='small'
                                            className={classes.textField}
                                            onChange={(e)=>setmodaltitle(e.target.value)}
                                        />
                                        &nbsp;&nbsp;
                                        <Button style={{height:35,width:80}} onClick={()=>onSaveUrutanProssess(hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)} className='btn-remove-capital btn-rounded' size='small' variant='contained' color='primary'>Simpan</Button>
                                    </div>}
                                </div>
                                <img onClick={()=>showUrutanProsess({...urutanProsesModal,[parseInt(`${hirarkiIndex1}${hirarkiIndex2}${hirarkiIndex3}`)]:!urutanProsesModal[parseInt(`${hirarkiIndex1}${hirarkiIndex2}${hirarkiIndex3}`)]},hirarki3.title)} src={renderIconEdit()} className='hirarki-icon'/>
                                {hirarkiIndex3!==0&&<img onClick={()=>deleteUruttanProsess(hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)} src={renderIconClose()} className='hirarki-icon'/>}
                            </div>
                            <Collapse in={collapse1[hirarki3.uuid]}>
                            <div className='content-sub'>
                                {hirarki3.detail!==null&&
                                    <HirarkiForm
                                        classes={classes}
                                        periode={periode}
                                        type={type}
                                        onChangeSub={onChangeSub2}
                                        hirarkiIndex1={hirarkiIndex1}
                                        hirarkiIndex2={hirarkiIndex2}
                                        hirarkiIndex3={hirarkiIndex3}
                                        detail={hirarki3.detail}
                                        is_edited={is_edited}
                                    />
                                }
                            </div>
                            
                            </Collapse>
                            {hirarkiIndex3===hirarki2.child.length-1&&is_edited&&
                            <div style={{width:'90%',marginTop:20,marginBottom:10,padding:'0px 0px 0px 13px'}}>
                                <div onClick={()=>tambahUrutanProsess(hirarkiIndex1,hirarkiIndex2,hirarkiIndex3)} className='btn-tambah-detail-aktivitas'>
                                    <p>Tambah Urutan Proses</p>
                                </div>
                            </div>}
                        </div>
                    ))}
                </div>
                
            </Collapse>
            {hirarkiIndex2===hirarki1.child.length-1&&is_edited&&
                <div style={{width:'95%',marginTop:20,marginBottom:10,padding:'0px 0px 0px 13px'}}>
                    <div  className='btn-tambah-detail-aktivitas' onClick={()=>tambahDetailActivitas(hirarkiIndex1,hirarkiIndex2)}>
                        <p>Tambah Detail Aktivitas</p>
                    </div>
                </div>}
            </div>
    )
}

export default HirarkiItemSub